.notification-window {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--borders-color);
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 458px;
  max-width: 90%;
  padding: 12px 15px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
  z-index: 10000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.notification-window.closing {
  opacity: 0;
}

.notification-window.error {
  background-color: var(--notification-error-background);
  color: var(--notification-error-color);
}

.notification-window.success {
  background-color: var(--notification-success-background);
  color: var(--notification-success-color);
}

.notification-window.info {
  background-color: var(--notification-info-background);
  color: var(--notification-info-color);
}

.notification-window.warning {
  background-color: var(--notification-error-background);
  color: var(--notification-error-color);
}

.notification-window--content {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 5px;
}

.notification-window--content svg {
  width: 15px;
  height: 15px;
}

.notification-window--close {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

:root {
  --notification-error-background: #FAF0F0;
  --notification-error-color: #F46C6C;
  --notification-info-background: #F0FAFC;
  --notification-info-color: #2D8EFF;
  --notification-success-background: #EFFBF2;
  --notification-success-color: #0EB857;
}

[data-theme='dark'] {
  --notification-error-background: #1A1F32;
  --notification-error-color: #F46C6C;
  --notification-info-background: #1A1F32;
  --notification-info-color: #2D8EFF;
  --notification-success-background: #1A1F32;
  --notification-success-color: #0EB857;
}