.transactions-block {
  background: var(--wallet-balance-background);
  border-radius: 20px;
  border: 1px solid var(--wallet-balance-border);
  padding: 20px;
  overflow-x: auto;
}

.transactions-block h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.transactions-table {
  width: 100%;
  border-collapse: collapse;
}

.transactions-table th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.transactions-table th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}


.transactions-table th,
.transactions-table td {
  text-align: left;
  padding: 10px;
}

.transactions-table th {
  background-color: #2D8EFF0D;
  font-weight: bold;
}

.transactions-table td {
  font-size: 14px;
  border-bottom: 1px solid var(--wallet-balance-border);
}

.transactions-table tbody tr:last-child td {
  border-bottom: none;
}