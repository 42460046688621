/* ProfileInfoSection.css */
.subscription-text {
  flex: 1;
  color: white;
  max-width: 450px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.banner-label {
  border-radius: 10px;
  padding: 10px;
  background: #FFFFFF1A;
  width: min-content;
  text-wrap: nowrap;
}

.subscription-text h2 {
  font-size: 50px;
  line-height: 55px;
  font-weight: 600;
  color: white;
  width: 100%;
}

.subscription-details {
  display: flex;
  justify-content: left;
  margin: 10px 0;
  gap: 10px;
  width: 100%;
}

.subscription-details .detail-item {
  text-align: left;
  background-color: var(--detail-item-background);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: calc(50% - 5px);
}

.subscription-details .detail-item p {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 0;
}

.subscription-details .detail-item img {
  margin-right: 5px;
}

.subscription-details .detail-item span {
  font-weight: 400;
  color: var(--detail-item-description-text);
}

.subscription-button {
  background-color: white;
  color: var(--profile-info-block);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.subscription-button:hover {
  color: white;
  background-color: var(--profile-info-block);
  padding: 9px 20px;
  border: 1px solid white;
}

.chuvachki {
  flex: 1;
  width: 50%;
  max-width: 500px;
}

@media (max-width: 800px) {
  .subscription-text h2 {
    font-size: 40px;
    line-height: 44px;
  }

  .subscription-button {
    width: 100%;
  }

  .chuvachki {
    margin-bottom: 30px;
    width: 100%;
  }
}