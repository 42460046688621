.ticket-window-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.ticket-window {
  display: flex;
  flex-direction: column;
  width: 390px;
  height: 610px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--ticket-window-background);
}

.ticket-window-label {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  color: var(--text-color);
}

.ticket-window-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  opacity: 0.8;
  margin-top: 5px;
  color: var(--text-color);
}

.ticket-window-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.ticket-window-divider {
  height: 1px;
  width: 100%;
  margin-top: 15px;
  background-color: var(--ticket-window-divider);
}

.create-ticket-button {
  border: none;
  border-radius: 10px;
  background-color: #2D8EFF;
  color: white;
  width: 100%;
  height: 45px;
}

:root {
  --ticket-window-background: white;
  --ticket-window-divider: #F5F5F5;
}

[data-theme='dark'] {
  --ticket-window-background: #1A1F32;
  --ticket-window-divider: #313131;
}