.App {
  display: flex;
  flex-direction: column;
  min-height: 92vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'EuclidCircularA';
  src: url('./fonts/EuclidCircularA-Regular.woff2') format('woff2'),
    url('./fonts/EuclidCircularA-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'EuclidCircularA';
  src: url('./fonts/EuclidCircularA-Bold.woff2') format('woff2'),
    url('./fonts/EuclidCircularA-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'EuclidCircularA', sans-serif !important;
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
  height: 96vh;
}

#root {
  background-color: var(--background-color);
  height: 100%;
}

.partner-chapter-box,
.home-chapter-box,
.label-chapter-box {
  color: #2D8EFF;
  border-radius: 15px;
  border: 1px solid #2D8EFF;
  height: 44px;
  padding: 13px 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  max-width: max-content;
}

.task-window-overlay,
.auth-window-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

@media (max-height: 650px) {
  .task-window-overlay,
  .auth-window-overlay {
    align-items: normal;
    overflow-y: auto;
  }
}

.no-scroll {
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

.mr-5 {
  margin-right: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.t-14 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.t-28 {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

.ghost-button {
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font: inherit !important;
  cursor: pointer !important;
}

.logo-15x15 {
  fill: var(--default-icon);
  min-width: 15px;
  width: 15px;
  height: 15px;
}

.logo-20x20 {
  fill: var(--default-icon);
  width: 20px;
  height: 20px;
}

.circle-25x25,
.circle-45x45 {
  border-radius: 50%;
  background-color: #2D8EFF;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-25x25 {
  width: 25px;
  height: 25px;
}

.circle-45x45 {
  width: 45px;
  height: 45px;
}

.currentColor {
  fill: currentColor;
}

.status-red,
.status-green,
.status-purple,
.status-yellow {
  height: 24px;
  border-radius: 10px;
  border: 1px solid;
  padding: 3px 10px;
  display: inline-flex;
  align-items: center;
}

.status-red {
  fill: #F25959;
  color: #F25959;
  border-color: #F25959;
  background-color: var(--status-red);
}

.status-green {
  fill: #0EB857;
  color: #0EB857;
  border-color: #0EB857;
  background-color: var(--status-green);
}

.status-purple {
  fill: #C259F2;
  color: #C259F2;
  border-color: #C259F2;
  background-color: var(--status-purple);
}

.status-yellow {
  fill: #FF9807;
  color: #FF9807;
  border-color: #FF9807;
  background-color: var(--status-yellow);
}

:root {
  --background-color: white;
  --borders-color: #e2e2e2;
  --links-color: #333333;
  --form-background: white;
  --color-info-block: #2D8EFF;
  --color-info-item: white;
  --color-buttons: #2D8EFF;
  --text-color: black;
  --dropdown-menu-color: white;
  --task-card-background: white;
  --task-card-border: #EFEFEF;
  --overlay-back: #F5F5F5;
  --search-field: #F9F9FA;
  --default-icon: #2D8EFF;

  --status-red: #FAF0F0;
  --status-green: #EFFBF2;
  --status-purple: #f9effe;
  --status-yellow: transparent;

}

[data-theme='dark'] {
  --background-color: #01061B;
  --borders-color: #2D3142;
  --links-color: #CCCDD1;
  --form-background: #1C2D42;
  --color-info-block: #2a78d6;
  --color-info-item: #3f86da;
  --color-buttons: #2D8EFF;
  --text-color: white;
  --dropdown-menu-color: #1A1F32;
  --task-card-background: #1A1F32;
  --task-card-border: #515151;
  --overlay-back: #01061B;
  --search-field: #F7F7F91A;
  --default-icon: #2D8EFF;

  --status-red: transparent;
  --status-green: transparent;
  --status-purple: transparent;
  --status-yellow: transparent;
}