.wallet-settings-content {
  display: flex;
  gap: 20px
}

.wallet-settings-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 80%;
}

.t-40 {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


/* Subscribe */
.wallet-subscribe-block {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  flex-shrink: 0;
  border-radius: 20px;
  background: #2D8EFF;
  padding: 20px;
}

.wallet-subscribe-container {
  display: flex;
  gap: 10px
}

.wallet-subscribe-container .buttons-container {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.wallet-subscribe-container .extend-btn {
  display: inline-flex;
  padding: 14px 48px 13px 48px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  background: #FFF;
  color: #2D8EFF;
  text-decoration: none;
}

.wallet-subscribe-container .change-btn {
  display: inline-flex;
  padding: 14px 46px 13px 47px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #FFF;
  background-color: transparent;
  color: white;
  text-decoration: none;
}

.wallet-subscribe-container.t-14 {
  justify-content: space-between;
}

/* Balance */
.wallet-settings-user-balance {
  display: flex;
  gap: 20px;
}

.wallet-balance-block {
  border-radius: 20px;
  border: 1px solid var(--wallet-balance-border);
  background: var(--wallet-balance-background);
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.wallet-balance-content {
  display: flex;
  height: 85px;
  justify-content: space-between;
}

.wallet-balance-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wallet-balance-container .button-link {
  border: none;
  border-radius: 10px;
  background: #2D8EFF;
  color: white;
  padding: 14px;
  width: 237px;
  text-wrap: nowrap;
  text-decoration: none;
  display: flex;
  justify-content: center;
}

@media (max-width: 1250px) {
  .wallet-settings-user-balance {
    flex-direction: column;
  }

  .wallet-balance-block {
    width: 100%;
  }

  .wallet-subscribe-container {
    align-items: center;
  }

  .wallet-subscribe-container .t-40 {
    font-size: 28px;
  }
}

@media (max-width: 1100px) {
  .wallet-subscribe-container .buttons-container {
    margin-left: 0;
    width: 100%;
  }

  .wallet-subscribe-container .buttons-container button {
    padding: 14px;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .wallet-settings-content {
    flex-direction: column;
  }

  .wallet-settings-container {
    width: 100%;
  }
}

@media (max-width: 732px) {
  .wallet-subscribe-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .wallet-subscribe-container.t-14 {
    flex-direction: row;
  }
}

@media (max-width: 550px) {
  .wallet-balance-content {
    flex-direction: column;
    height: auto;
    gap: 15px;
  }

  .wallet-balance-container {
    gap: 5px;
  }

  .wallet-balance-container button {
    margin-bottom: 5px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .wallet-subscribe-container.t-14 {
    flex-direction: column;
  }
}

:root {
  --wallet-balance-background: white;
  --wallet-balance-border: #EFEFEF;
}

[data-theme='dark'] {
  --wallet-balance-background: #1A1F32;
  --wallet-balance-border: #515151;
}