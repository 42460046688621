.filter-window {
  position: absolute;
  width: 400px;
  border-radius: 10px;
  background: var(--filter-window-background);
  color: var(--text-color);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 950;
  padding: 15px;
  text-align: left;

  h5 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.filter-section {
  border-top: 1px solid #E3E3E4;
  padding: 15px 0;
  gap: 10px
}

.filter-section--header {
  display: flex;
  justify-content: space-between;
}

.filter-section--header span {
  opacity: 0.5;
}

.filter-section--header button {
  color: #2d8eff;
}

.filter-section--items {
  display: flex;
  justify-content: space-between;
}

.filter-section--items label {
  display: flex;
  gap: 5px;
  opacity: 0.5;
  align-items: center;
}

.filter-section--items span {
  border-radius: 50%;
  border: 2px solid #D9D9D9;
  height: 15px;
  width: 15px;
}

.filter-section--items label.active {
  opacity: 1;
}

.filter-section--items label.active span {
  border: 3px solid #2d8eff;
}

:root {
  --filter-window-background: white;
}

[data-theme='dark'] {
  --filter-window-background: #484C5B;
}