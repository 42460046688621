.home-header,
.reviews,
.about,
.how-it-work,
.tariffs,
.faq {
  max-width: 1240px;
  margin: 0 auto;
  height: 100%;
}

.home-login-button,
.home-register-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.home-login-button {
  width: 107px;
  background-color: transparent;
  color: white;
}

.home-register-button {
  width: 170px;
  border-radius: 15px;
  color: #2D8EFF;
  background-color: white;
}

:root {
  --uneven-box-background: #F9FAFB;
  --how-it-work-menus-background: #F7F7F9;
  --how-it-work-menu-text: black;
  --how-it-work-menu-background-active: white;
  --how-it-work-container-border: #E7EAEE;
  --tariff-card-background: #FFFFFF;
  --tariff-card-border: #E7EAEE;
}

[data-theme='dark'] {
  --uneven-box-background: #1A1F32;
  --how-it-work-menu-text: #FFFFFF80;
  --how-it-work-menu-background-active: #FFFFFF1A;
  --how-it-work-container-border: #515151;
  --tariff-card-background: #FFFFFF1A;
  --tariff-card-border: #E7EAEE1A;
  --how-it-work-menus-background: #F7F7F91A;
}