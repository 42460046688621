.task-form-blue-rectangle {
    background-color: var(--color-info-block);
    color: white;
    border-radius: 20px;
    padding: 14px;
    width: 370px;
    height: 623px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.task-form-blue-rectangle h1 {
    font-size: 45px;
    font-weight: 600;
    text-align: left;
    width: 320px;
}

@media (max-width: 800px) {
    .task-form-blue-rectangle {
        display: none;
    }
}