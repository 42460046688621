.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
    padding: 15px 0;
  background-color: var(--background-color);
  color: var(--text-color);
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
}

.header::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--borders-color);
  position: absolute;
  bottom: 0;
}

.header-left .header-title {
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color);
    margin-bottom: 0;
}

.header-center {
  gap: 27px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
  position: relative;
}

.header-link {
  text-decoration: none;
  color: var(--links-color);
  font-size: 16px;
  white-space: nowrap;
}

.header-link.active {
  color: var(--color-buttons);
}

.header-button {
  margin-left: 10px;
  padding: 13px 35px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 15px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 2px solid var(--color-buttons);
  height: 45px;
  display: flex;
  align-items: center;
}

.register-button {
  background-color: var(--color-buttons);
  color: white;
    border: 0;
  width: 167px;
}

.narrow-button {
  border: 1px solid var(--color-buttons);
}

.hiding-button {
  padding: 0;
  cursor: pointer;
  border-radius: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 0 solid var(--background-color);
}

.theme-toggle {
  margin-left: 10px;
  padding: 13px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 15px;
  background-color: var(--background-color);
  color: var(--text-color);
  height: 45px;
  display: flex;
  align-items: center;
}

.header.light {
  background-color: var(--background-color);
  color: var(--text-color);
}

.header.dark {
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Стили для выпадающего меню */
.user-dropdown-menu {
  position: absolute;
  top: 70px;
  right: 0;
  background-color: var(--header-dropdown-menu);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 215px;
  padding: 5px;
  z-index: 1000;
  color: var(--text-color);
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile-name {
  font-weight: 600;
}

.user-dropdown-menu-item {
  /* padding: 5px 0px; */
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  color: var(--text-color);
  font-weight: 400;
}

.user-dropdown-menu-item img {
  margin: 0 10px;
}

.user-dropdown-menu-item:last-child {
  border-bottom: none;
}

.profile-icon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.menu-theme-toggle-desktop {
  width: 15px;
  height: 15px;
  margin-left: auto;
  cursor: pointer;
}

.logout-button {
  margin: 0;
  align-items: center;
  background-color: var(--color-buttons);
  color: white;
    border: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  height: 39px;
  font-weight: 400;
}

.header-icon {
  fill: currentColor;
  width: 15px;
  height: 15px;
}


@media (max-width: 1280px) {
  .header {
    max-width: calc(100% - 40px);
  }
}

@media (max-width: 1100px) {
  .optional {
    display: none;
  }
}

:root {
  --header-dropdown-menu: white;
}

[data-theme='dark'] {
  --header-dropdown-menu: #1A1F32;
}