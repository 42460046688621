/* FaQ */
.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  padding: 55px 0;
  height: auto;
}

.faq-label {
  font-size: 55px;
  font-weight: 500;
  line-height: 60px;
  margin-top: 10px;
  max-width: 530px;
}

.faq-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 15px;
}

.faq-item {
  flex: 1;
  min-width: 390px;
  padding: 10px 15px;
  border: 1px solid var(--faq-item-border);
  border-radius: 15px;
  cursor: pointer;
  height: min-content;
  transition: max-height 0.3s ease;
}

.faq-question {
  display: flex;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  justify-content: space-between;
  align-items: center;
}

.faq-question-rightside .faq-angle {
  transition: transform 0.3s ease;
}

.faq-question-rightside .faq-angle.open {
  transform: rotate(270deg); /* Поворачиваем на 90 градусов */
}

.faq-question-leftside {
  display: flex;
  gap: 15px;
  align-items: center;
}

.faq-question-logo {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: var(--faq-item-logo);
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq-question-logo svg {
  width: 15px;
  height: 13px;
}

.faq-question-rightside svg {
  width: 7px;
  height: 15px;
  transform: rotate(90deg);
}

.faq-button {
  width: 235px;
  height: 50px;
  border: none;
  border-radius: 15px;
  color: white;
  background-color: #2D8EFF;
  margin-top: 20px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

}

@media (max-width: 800px) {
  .faq {
    padding: 35px 0;
  }

  .faq-label {
    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
  }

  .faq-question {
    font-size: 16px;
    line-height: 20px;
  }

  .faq-item {
    min-width: 300px;
  }

  .faq-button {
    width: 100%;
  }
}

:root {
  --faq-item-border: #E5E5E5;
  --faq-item-logo: #F9FAFB;
}

[data-theme='dark'] {
  --faq-item-border: #E5E5E51A;
  --faq-item-logo: #F9FAFB1A;
}