/* Стили для окна регистрации */
@import 'AuthWindow.css';

.login-main-text {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.login-info-block-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.login-info-block {
  background-color: var(--auth-info-item);
  color: var(--text-color);
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  text-align: center;
  max-width: 300px;
  line-height: 1.2;
}

.login-info-block-title {
  font-size: 14px;
  margin-left: 5px;
  align-items: center;
}

.login-info-block-description {
  font-size: 12px;
  margin-top: 5px;
}

.reset-password-btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 10px;
  color: #2D8EFF;
  cursor: pointer;
}

.block1 {
  margin-top: 15px;
  margin-left: 186px;
  width: 165px;
  height: 55px;
  display: flex;
  align-items: center;
}

.block2 {
  margin-top: 60px;
  margin-left: 3px;
  width: 165px;
  height: 154px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block3 {
  margin-top: 182px;
  margin-left: 186px;
  width: 165px;
  height: 101px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block4 {
  margin-top: 298px;
  margin-left: 3px;
  width: 226px;
  height: 55px;
  display: flex;
  align-items: center;
}