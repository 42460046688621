/* Header Logo */
.home-header-background {
  background-color: #2D8EFF;
  background-image: url('@assets/home/headerBackground.svg');
  background-position: center;
}

.home-header {
  padding-top: 13px;
  width: 90%;
}

.home-header-nav-bar {
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-toup-container {
  display: flex;
  align-items: baseline;
  gap: 0;
  font-size: 20px;
  font-weight: 600;
  color: white;
    margin-bottom: 0;
}

.logo-toup {
  width: 15px;
  height: 11px;
}

.home-header-links-container {
  display: flex;
  gap: 63px;
}

.home-header-link {
  color: white;
  text-decoration: none;
}

.home-header-auth-buttons {
  display: flex;
}

.home-header-auth-buttons img {
  cursor: pointer;
}

.home-hamburger-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  fill: white !important;
}

.home-hamburger-button svg {
  width: 25px;
  height: 25px;
}

@media (max-width: 1050px) {

  .home-header-links-container,
  .home-header-auth-buttons {
    display: none;
  }

  .home-hamburger-button {
    display: block !important;
  }
}


/* Header Info */
.home-header-welcome-info {
  display: flex;
  align-items: center;
  margin-top: 59px;
  margin-bottom: 118px;
}

.home-header-welcome-info-text {
  width: 562px;
}

.home-header-welcome-info-text .welcome {
  border: 1px solid white;
  border-radius: 15px;
  color: white;
  width: 196px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-header-welcome-info-text .label {
  font-size: 75px;
  font-weight: 500;
  color: white;
  line-height: 80px;
}

.logo-toup-label {
  width: 45px;
  height: 45px;
}

.home-header-welcome-info-text .text {
  font-size: 14px;
  font-weight: 400;
  color: white;
  margin-top: 10px;
}

.home-header-welcome-info-text .buttons {
  display: flex;
  margin-top: 15px;
}

.start-btn {
  height: 50px;
  width: 227px;
  border: 1px solid white;
  border-radius: 15px;
  padding: 15px 55px;
  background-color: white;
  color: #2D8EFF;
  white-space: nowrap
}

.detail-btn {
  height: 50px;
  width: 227px;
  color: white;
}

.about-container {
  margin-top: 65px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.about-item {
  color: white;
}

.about-label {
  font-weight: 500;
  font-size: 45px;
  color: white;
}

.about-description {
  font-weight: 400;
  font-size: 16px;
}

.vertical-divider {
  height: 35px;
  width: 2px;
  background-color: #F1F1F1;
  opacity: 0.5;
}

.home-header-welcome-info-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-header-welcome-info-img img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1100px) {
  .home-header-welcome-info {
    margin-top: 30px;
    margin-bottom: 35px;
  }

  .home-header-welcome-info-text {
    width: 387px;
  }

  .home-header-welcome-info-text .label {
    font-size: 45px;
    font-weight: 500;
    color: white;
    line-height: 50px;
  }

  .logo-toup-label {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 750px) {
  .home-header-welcome-info {
    flex-direction: column;
    gap: 25px;
  }

  .home-header-welcome-info-text {
    width: 90%;
  }
}