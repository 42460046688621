.banner-slider {
  position: relative;
  width: 100%;
  margin: 20px 0;
  overflow: hidden;
  touch-action: pan-y; /* Только горизонтальный свайп */
  cursor: grab;
  user-select: none;
}

.banner-slider:active {
  cursor: grabbing;
}

.banners-container {
  display: flex;
  will-change: transform;
}

.banners-container .banner {
  flex: 0 0 calc(100% - 20px);
  display: flex;
  margin: 0 10px;
  align-items: center;
  justify-content: center;
}

.banners-container .banner section {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: var(--profile-info-block);
  padding: 20px;
  margin: 20px 0;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  /*height: min-content;*/
  gap: 50px;
}

/* Индикаторы (точки) */
.dots-container {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 15px;
}

.dot {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dot.active {
  background-color: #fff;
  animation: fillDot 0.3s forwards;
}

@media (max-width: 800px) {
  .banners-container .banner section {
    flex-direction: column;
  }
}

/* Анимация заполнения точки */
@keyframes fillDot {
  0% {
    background-color: transparent;
    transform: scale(1);
  }
  100% {
    background-color: #fff;
    transform: scale(1.2);
  }
}
