.input-field {
  width: 100%;
  box-sizing: border-box;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
}

.input-field.is-focused {
  color: #2d8eff;
}

.input-field.hidden {
  opacity: 0.5;
}

.input-field label {
  display: block;
  margin-bottom: 2px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
  line-height: 18px;
}

.input-field .change-btn {
  margin-left: auto;
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--input-field-borders);
  border-radius: 10px;
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.input-container.error {
  border: 1px solid var(--error-border);
  background-color: var(--error-background);
}

.input-container svg {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.input-container.error svg {
  color: var(--error-border);
  fill: currentColor;
}

.input-field-divider {
  width: 1px;
  height: 20px;
  background-color: var(--input-field-borders);
  margin-right: 5px;
  transition: background-color 0.3s ease;
}

.input-container.error .input-field-divider {
  background-color: var(--error-border);
}

.input-field.is-focused .input-container {
  border: 1px solid #2d8eff;
  color: #2d8eff;
}

.input-field.is-focused .input-container svg {
  color: #2d8eff;
  fill: currentColor;
}

.input-field.is-focused .input-container .input-field-divider {
  background-color: #2d8eff;
}

.input-field.is-focused .input-container input,
.input-field.is-focused .input-container textarea {
  color: #2d8eff;
}

.input-container input,
.input-container select,
.input-container textarea {
  border: none;
  flex: 1;
  outline: none;
  background: transparent;
  color: var(--text-color);
  fill: #2D8EFF;
  width: 100%;
  box-sizing: border-box;
  transition: color 0.3s ease;
}

.input-container select {
  background-color: var(--input-field-background);
  border: none;
  border-radius: 10px;
}

.input-container option {
  color: var(--text-color);
  background-color: var(--select-field-option-background);
}

.input-container textarea {
  height: 115px;
  resize: none;
  vertical-align: auto;
}

.input-container.error input,
.input-container.error select {
  background-color: var(--error-background);
  color: var(--error-border);
}

.input-container .select-field {
  position: relative;
  width: 100%;
}

.input-container .select-field .selector {
  display: flex;
  justify-content: space-between;
}

.input-container .select-field .options {
  position: absolute;
  top: 38px;
  width: 100%;
  background-color: var(--select-field-option-background);
  border: 1px solid var(--input-field-borders);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.input-container .select-field .options .option {
  padding: 10px;
  cursor: pointer;
  color: var(--text-color);
  transition: background-color 0.3s ease;
}

.input-container .select-field .options .option:hover {
  background-color: #2D8EFF;
}

.input-container .select-field .divider {
  width: 100%;
  height: 1px;
  background-color: var(--input-field-borders);
}

.little-select-field {
  width: 90px !important;
  height: 26px;
  background-color: var(--select-field-option-background) !important;
  padding-left: 10px;
  border: none;
  cursor: pointer;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: relative;
  /* filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%); */
}

.type-count-selection {
  position: absolute;
  top: 393px;
  left: 200px;
  width: 171px;
  background-color: var(--task-card-background);
  border: 1px solid var(--input-field-borders);;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.type-count-option {
  padding: 10px;
  background-color: var(--select-field-option-background);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.type-count-selection .divider {
  width: 100%;
  height: 1px;
  background-color: var(--input-field-borders);
}

.big-text {
  height: 135px;
}

.big-text-field {
  height: 100%;
  text-align: left;
}

:root {
  --little-input-field: #F5F5F5;
  --input-field-background: white;
  --input-field-borders: #e2e2e2;
  --select-field-option-background: white;
  --error-border: #F46C6C;
  --error-background: #FAF0F0;
}

[data-theme='dark'] {
  --little-input-field: #F5F5F51A;
  --input-field-background: #1A1F32;
  --input-field-borders: #DEDEDE4D;
  --select-field-option-background: #303546;
  --error-border: #F46C6C;
  --error-background: #1A1F32;
}

[data-theme='dark'] input[type='number']::-webkit-outer-spin-button,
[data-theme='dark'] input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  color: #FFFFFF;
  /* Цвет стрелок */
}
