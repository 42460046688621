.calendar-settings-container {
  padding: 20px;
  opacity: 0;
  animation: fadeIn 0.2s ease-in forwards;
}

.calendar-container {
  display: flex;
  justify-content: space-between;
  width: 677px;
  height: 520px;
  border-radius: 20px;
  gap: 15px
}

.calendar-item {
  flex: 1 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.calendar-title-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
}

.calendar-title-container h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: var(--text-color);
  margin-bottom: 0;
}

.calendar-title-container span {
  font-weight: 400;
  opacity: 0.8;
}

.calendar-title-mode-toggle {
  display: flex;
  justify-content: space-between;
  background-color: var(--how-it-work-menus-background);
  border-radius: 15px;
  padding: 5px;
  width: min-content;
  /* height: 40px; */
  flex-wrap: nowrap;
}

.calendar-title-mode-toggle button {
  color: var(--text-color);
  border: none;
  background-color: transparent;
  font-weight: 400;
  height: 30px;
  border-radius: 10px;
  white-space: nowrap;
  padding: 9px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.calendar-title-mode-toggle .active {
  color: #2D8EFF;
  border: none;
  background-color: var(--calendar-title-toggle-active);
}

.calendar-weekly,
.calendar-calendar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.calendar-weekly-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.calendar-weekly-days-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.calendar-weekly-days-container button {
  border: 1px solid var(--calendar-title-toggle-border);
  background-color: transparent;
  color: var(--calendar-title-toggle-text);
  width: 66px;
  height: 30px;
  border-radius: 5px;
}

.calendar-weekly-days-container .active {
  border: none;
  background-color: #2D8EFF;
  color: white;
}

.calendar-weekly.inactive,
.calendar-calendar.inactive {
  opacity: 0.5;
  pointer-events: none;
}

.execution-time {
  background-color: var(--calendar-execution-time);
  border-radius: 15px;
  padding: 10px 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5px;
  /*width: 310px;*/
}

.execution-time-label {
  display: flex;
}

.add-btn {
  margin-left: auto;
}

.delete-btn {
  margin-left: 5px;
}

.execution-time .time-container {
  display: flex;
  gap: 10px;
}

.working-hours-container {
  width: 100%;
  height: 215px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  margin-top: auto;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid var(--calendar-title-toggle-border);
}

.working-hours-container {
  scrollbar-width: thin;
  /* Для Firefox */
  scrollbar-color: #888 #f1f1f1;
  /* Цвет трека и ползунка */
}

.working-hours-container::-webkit-scrollbar {
  width: 6px;
  /* Ширина скроллбара */
}

.working-hours-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Цвет фона трека */
  border-radius: 10px;
}

.working-hours-container::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Цвет ползунка */
  border-radius: 10px;
  /* Округление ползунка */
}

.working-hours-container::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Цвет ползунка при наведении */
}

.calendar-vertical-divider {
  height: 100%;
  width: 1px;
  border: 1px solid #E3E3E4;
}

.calendar-buttons-container {
  display: flex;
  gap: 30px;
}

.calendar-default-btn,
.calendar-save-btn {
  display: flex;
  width: 100%;
  padding: 11px 0;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: none;
}

.calendar-save-btn {
  background: #2D8EFF;
  color: white;
}

.calendar-default-btn {
  background: transparent;
  color: #2D8EFF;
  border: 1px solid #2D8EFF;
}


.react-datepicker__aria-live {
  display: none;
}

.react-datepicker {
  border: none !important;
  font-family: 'EuclidCircularA', sans-serif !important;
  user-select: none !important;
  background-color: transparent !important;
  color: var(--text-color) !important;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: none !important;
  font-size: 16px !important;
}

.react-datepicker__current-month {
  font-size: 1.5em !important;
  text-align: center !important;
  margin-bottom: 10px !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 32px !important;
  height: 32px !important;
  line-height: 2.5rem !important;
  font-size: 1rem !important;
  color: var(--text-color) !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 1px 5px !important;
  border-radius: 50% !important;
}

.react-datepicker__day:not(.disable-hover):hover {
  color: var(--datepicker-day-hover-color) !important; /* Черный цвет текста при наведении */
  background-color: var(--datepicker-day-hover-background) !important; /* Сохранение светлого фона для выделения */
  cursor: pointer; /* Указатель в виде руки */
}

/*.react-datepicker__day.disable-hover {*/
/*  pointer-events: none; !* Запретить взаимодействие с элементами *!*/
/*  color: var(--text-color) !important; !* Сохранение стандартного цвета *!*/
/*  background-color: transparent !important; !* Убрать фон *!*/
/*}*/

.react-datepicker__day--today {
  color: #2D8EFF !important;
  font-weight: bold !important;
  background-color: transparent !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--range-end {
  background-color: #2A90FF !important;
  color: white !important;
}

.react-datepicker__day--in-range:not(.react-datepicker__day--selected),
.react-datepicker__day--highlighted-in-range-days {
  background-color: var(--calendar-day-in-range) !important;
  color: #2A90FF !important;
}

.react-datepicker__day--highlighted-border-start-days,
.react-datepicker__day--highlighted-border-end-days {
  background-color: #2A90FF !important;
  color: white !important;
}

.react-datepicker__navigation--previous {
  top: 1rem !important;
  left: 1rem !important;
  border: none !important;
  background: none !important;
}

.react-datepicker__navigation--next {
  top: 1rem !important;
  right: 1rem !important;
  border: none !important;
  background: none !important;
}

.react-datepicker__navigation-icon::before {
  content: '' !important;
  border: solid #2A90FF !important;
  border-width: 0 2px 2px 0 !important;
  display: inline-block !important;
  padding: 3px !important;
}

.react-datepicker__navigation-icon--previous::before {
  transform: rotate(135deg) !important;
  -webkit-transform: rotate(135deg) !important;
}

.react-datepicker__navigation-icon--next::before {
  transform: rotate(-45deg) !important;
  -webkit-transform: rotate(-45deg) !important;
}

.react-datepicker__day:not(.disable-hover):hover {
  background-color: #e0f7fa !important; /* Цвет для выделенного дня */
  color: #000 !important; /* Цвет текста для выделенного дня */
}

@media (max-width: 900px) {
  .calendar-container {
    flex-direction: column;
    width: 390px;
    height: 680px;
  }

  .calendar-item {
    width: 100%;
  }

  .calendar-vertical-divider,
  .calendar-weekly.inactive,
  .calendar-calendar.inactive,
  .calendar-default-btn {
    display: none;
  }

  .calendar-settings-container {
    padding: 10px;
  }
}

:root {
  --calendar-title-toggle-active: #FFFFFF;
  --calendar-title-toggle-text: #808080;
  --calendar-title-toggle-border: #EBEBEB;
  --calendar-day-in-range: #e3f2ff;
  --calendar-execution-time: #F8F8F8;
  --datepicker-day-hover-color: #fff;
  --datepicker-day-hover-background: #e0f7fa;
}

[data-theme='dark'] {
  --calendar-title-toggle-active: #FFFFFF1A;
  --calendar-title-toggle-text: #8C8F98;
  --calendar-title-toggle-border: #EBEBEB4D;
  --calendar-day-in-range: #2D8EFF1A;
  --calendar-execution-time: #2D3142;
  --datepicker-day-hover-color: #000;
  --datepicker-day-hover-background: #e0f7fa;
}