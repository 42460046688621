/* About */
.about-background {
  background-color: var(--uneven-box-background);
  padding: 55px 0;
}

.about {
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  max-width: 530px;
}

.about-service {
  color: #2D8EFF;
  border: 1px solid #2D8EFF;
  border-radius: 15px;
  height: 44px;
  width: 151px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-main .label {
  color: var(--text-color);
  font-size: 55px;
  font-weight: 500;
  line-height: 60px;
  margin-top: 10px;
}

.about-main .text {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  opacity: 0.8;
  margin-top: 10px;
}

.about-table-item {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 15px;
}

.about-table-item svg {
  fill: #27B973;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.create-account-button {
  color: white;
  border-radius: 15px;
  margin-top: 20px;
  background-color: #2D8EFF;
  width: 252px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.about-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 1100px) {
  .about-main .label {
    font-size: 45px;
    font-weight: 500;
    line-height: 50px;
  }
}

@media (max-width: 800px) {
  .about {
    flex-direction: column;
    gap: 25px;
  }

  .create-account-button {
    width: 100%;
  }
}