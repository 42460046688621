/* Empty support */
.empty-support {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.empty-support .label {
  font-size: 26px;
  font-weight: 600;
  line-height: 33px;
  margin-top: 30px;
  color: var(--text-color);
}

.empty-support .description {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
  line-height: 18px;
  margin-top: 10px;
  margin-bottom: 15px;
  color: var(--text-color);
}

.create-ticket-button {
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #2D8EFF;
  border: none;
  width: 180px;
  height: 45px;
  border-radius: 10px;
}

/* Support */
.support {
  width: 1240px;
  height: 100%;
  align-self: center;
}

.support-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 22px 0;
}

.support .text {
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
}

.support-content {
  width: 1240px;
  height: 80vh;
  border: 1px solid var(--support-window-borders);
  display: flex;
  border-radius: 10px;
  background-color: var(--support-window-background);
}

.support-dialogs {
  width: 30%;
  height: 100%;
  border-right: 1px solid var(--support-window-borders);
  padding: 10px;
  overflow-y: scroll;
  /* border-radius: 10px */
}

/* Кастомизация скроллбара */
.support-dialogs::-webkit-scrollbar {
  width: 4px;
}

.support-dialogs::-webkit-scrollbar-thumb {
  background-color: #2D8EFF;
  border-radius: 2px;
}

.support-dialogs::-webkit-scrollbar-thumb:hover {
  background-color: #1B6DCC;
}

.support-dialogs::-webkit-scrollbar-track {
  background-color: #E7EAEE;
  border-radius: 2px;
}

.dialog-item {
  max-width: 378px;
  height: 73px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 15px 10px;
}

.dialog-item.active {
  background-color: #2D8EFF1A;
}

.support-dialog-logo {
  min-width: 35px;
  height: 35px;
  border-radius: 18px;
  background-color: #EAECF0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.support-dialog-logo svg {
  width: 15px;
  height: 15px;
  fill: black;
}

.dialog-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-info .label {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color);
}

.dialog-info .time {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.6;
  color: var(--text-color);
  line-height: 16px;
}

.dialog-item .message {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.8;
  color: var(--text-color);
  max-width: 288px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Messages */
.support-messages {
  width: 70%;
  height: 100%;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.label-content {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 15px 12px 18px 12px;
}

.label-content .text,
.label-content .id {
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  color: var(--text-color);
}

.label-content .id {
  opacity: 0.5;
}

.label-content .status {
  font-size: 14px;
  font-weight: 400;
  /*color: var(--text-color);*/
  text-align: right;
  border: 1px solid #0EB857;
  background-color: var(--support-dialog-status-background);
  color: #0EB857;
  display: inline-flex;
  gap: 5px;
}

.support-dialog-window {
  width: 100%;
  height: calc(78vh - 110px);
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 0 12px;
}

.question-window {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--question-window-background);
}

.question-window .question-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--support-window-borders);
  padding-bottom: 10px;
}

.question-top .user {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color);
}

.question-top .date {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
  color: var(--text-color);
}

.question-type {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
  margin-top: 15px;
  padding: 8px 15px;
  width: min-content;
  height: 34px;
  border-radius: 10px;
  background-color: #2D8EFF1A;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-wrap: nowrap;
}

.question-text {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
  opacity: 0.8;
  margin-top: 10px;
}

.message-container {
  display: flex;
  align-items: flex-start;
  /* padding: 10px; */
  margin: 15px 0;
  gap: 10px;
  width: 100%;
  height: auto;
}

.message-container.user {
  justify-content: flex-end;
}

.message-container.user .message-logo {
  order: 2;
}

.message-container.user .message-block {
  background-color: var(--message-container-user);
  border-radius: 10px 0 10px 10px;
}

.message-container.user .message-main {
  align-items: flex-end;
}

.message-container.user .label {
  flex-direction: row-reverse;
}

.message-container.support {
  justify-content: flex-start;
}

.message-container.support .message-block {
  background-color: var(--message-container-support);
  border-radius: 0 10px 10px 10px;
}

.message-container .message-main {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.message-container .label {
  display: flex;
  align-items: center;
  gap: 10px;
}

.message-container .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.message-logo {
  min-width: 35px;
  height: 35px;
  border-radius: 18px;
  background-color: #EAECF0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-block {
  padding: 15px;
  max-width: 29rem;
}

.message-block .label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.label .user-name {
  font-weight: 600;
  color: var(--text-color);
}

.label .time {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.6;
  color: var(--text-color);
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color);
}

.date-separator {
  font-size: 12px;
  font-weight: 400;
  opacity: 0.6;
  color: var(--text-color);
  text-align: center;
  margin: 15px 0;
}

/* Message Field */
.message-input-container {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  border-top: 1px solid var(--support-window-borders);
  height: auto;
  max-height: 230px;
  
}

.message-input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  color: var(--text-color);
  box-sizing: border-box;
  width: auto;
  max-height: 229px;
  background-color: var(--support-window-background);
}

.message-input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.message-input-container button {
  height: 35px;
  width: 45px;
  background-color: var(--support-window-background);
  fill: black;
  border: 1px solid var(--support-window-borders);
  /* padding: 10px 20px; */
  border-radius: 10px;
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

.select-chat {
  display: flex;
  margin: auto;
}

@media (max-width: 1280px) {
  .support {
    max-width: calc(100% - 40px);
  }

  .support-content {
    width: 100%;
  }

  .label-content .text {
    font-size: 24px;
  }

  .question-text,
  .message-block .text,
  .message,
  .question-type,
  .label .user-name {
    font-size: 12px;
  }

  .message-field {
    padding: 15px;
  }
}



@media (max-width: 850px) {

}

:root {
  --support-window-background: white;
  --support-window-borders: #E7EAEE;
  --support-dialog-status-background: #EFFBF2;
  --question-window-background: #F9F9FA;
  --message-container-support: #F4F7FF;
  --message-container-user: #E4F4FF;
}

[data-theme='dark'] {
  --support-window-background: #1A1F32;
  --support-window-borders: #515151;
  --support-dialog-status-background: #1A1F32;
  --question-window-background: #FFFFFF0D;
  --message-container-support: #FFFFFF0D;
  --message-container-user: #294571;
}