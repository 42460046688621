.profile-settings-telegram-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile-settings-telegram-item {
  display: flex;
  gap: 5px;
  border-radius: 10px;
  background-color: #2D8EFF1A;
  padding: 5px 10px;
}

.add-channel-btn {
  width: 350px;
  padding: 13px 119px 14px 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #2D8EFF;
  color: #2D8EFF;
  background-color: transparent;
  text-wrap: nowrap;
}

.profile-settings-telegram-item .telegram-name {
  display: inline-flex;
  padding: 9px 15px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: var(--profile-settings-telegram-name-background);
  color: #2D8EFF;
  width: max-content;
  max-width: 50%;
}

.profile-settings-telegram-item .telegram-link {
  display: flex;
  width: 100%;
  padding: 9px 15px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #2D8EFF;
  color: #2D8EFF;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-settings-telegram-item .edit-btn {
  display: flex;
  min-width: 43px;
  height: 43px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--profile-settings-telegram-name-background);
  border: none;
}

.profile-settings-telegram-item .edit-btn:hover {
  border: 2px solid var(--profile-settings-telegram-name-background);
  background: transparent;
}

.profile-settings-telegram-item .edit-btn.mobile {
  display: none;
}

.profile-settings-telegram-item .telegram-settings-mobile {
  display: none;
}

@media (max-width: 900px) {
  .profile-settings-telegram-item .edit-btn.desktop {
    display: none;
  }

  .profile-settings-telegram-item .edit-btn.mobile {
    display: flex;
  }

  .profile-settings-telegram-item .telegram-settings-mobile {
    display: flex;
    gap: 5px;
    position: absolute;
    flex-direction: column;
    background-color: #EBF3FF;
    padding: 5px;
    border-radius: 10px;
    box-shadow: var(--tasks-pagination-shadow);
  }
}

@media (max-width: 600px) {
  .add-channel-btn {
    width: 100%;
  }
}

:root {
  --profile-settings-telegram-name-background: white;
}

[data-theme='dark'] {
  --profile-settings-telegram-name-background: rgba(255, 255, 255, 0.10);
}
