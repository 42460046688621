.about-reputation {
  display: flex;
  flex-direction: column;
  width: 1240px;
  margin: 65px auto 0 auto;
  gap: 15px;
}

.about-reputation .label {
  color: var(--text-color);
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.about-reputation .description {
  width: 610px;
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.about-reputation .about-reviews-container {
  display: flex;
  gap: 20px;
}

.about-reputation .about-reviews-container .review-item {
  display: flex;
}

.about-reputation .about-reviews-container .review-item .review-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.about-reputation .about-reviews-container .slash {
  fill: var(--about-news-card-border);
}

.about-reputation .about-reviews-container .review-item .slash.mobile {
  display: none;
  fill: var(--about-news-card-border);
  height: 110px;
}

.about-reputation .about-reviews-container .review-item .review-card .users-text,
.about-reputation .about-reviews-container .review-item .review-card .review-text,
.about-reputation .about-reviews-container .review-item .review-card .payment-text {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.about-reputation .about-reviews-container .review-item .review-card .users-text {
  max-width: 321px;
}

.about-reputation .about-reviews-container .review-item .review-card .users-container {
  display: flex;
  margin-left: 45px;
}

.about-reputation .about-reviews-container .review-item .review-card .users-container img {
  position: relative;
}


@media (max-width: 1280px) {

  .about-reputation {
    width: calc(100% - 40px);
  }
}

@media (max-width: 700px) {
  .about-reputation .label {
    font-size: 35px;
    line-height: 40px;
  }

  .about-reputation .description {
    width: 100%;
  }

  .about-reputation .about-reviews-container {
    flex-direction: column;
  }

  .about-reputation .about-reviews-container .slash {
    display: none;
  }

  .about-reputation .about-reviews-container .review-item.back {
    display: flex;
    flex-direction: row-reverse;
  }

  .about-reputation .about-reviews-container .review-item .review-card .users-text {
    width: 170px;
  }

  .about-reputation .about-reviews-container .review-item .review-card .review-text {
    width: 190px;
  }

  .about-reputation .about-reviews-container .review-item .review-card .payment-text {
    width: 150px;
  }

  .about-reputation .about-reviews-container .review-item .slash.mobile {
    display: block;
  }
}

:root {
  --about-reputation-slash: #F1F1F1;
}

[data-theme='dark'] {
  --about-reputation-slash: rgba(241, 241, 241, 0.20);
  ;
}