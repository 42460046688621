.task-window {
  background: var(--task-card-background);
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /*width: 0; !* Начальное значение *!*/
  /*height: 0; !* Начальное значение *!*/
  opacity: 0; /* Начальная прозрачность */
  transition: width 0.2s ease, height 0.2s ease, opacity 100ms ease;
}

.task-window.default {
  width: 778px;
  height: 643px;
  opacity: 1;
}

.task-window.advanced-settings {
  width: 540px;
  height: 598px;
  opacity: 1;
}

.task-window.calendar {
  width: 717px;
  height: 608px;
  opacity: 1;
}

.task-window-back-btn {
  width: 45px;
  height: 45px;
  padding: 13px;
  border-radius: 10px;
  border: 1px solid var(--task-settings-border);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.task-window-back-btn svg {
  opacity: 0.5;
  fill: var(--text-color);
}

@media (max-width: 800px) {
  .task-form {
    margin: 0;
  }

  .task-content {
    padding: 10px 15px;
  }

  .task-window.default {
    width: 380px;
    height: 629px;
    opacity: 1;
  }

  .task-window.advanced-settings {
    width: 386px;
    height: 552px;
    opacity: 1;
  }

  .task-window.calendar {
    width: 410px;
    height: 750px;
    opacity: 1;
  }
}

@media (max-height: 650px) {
  .task-form-blue-rectangle {
    display: none;
  }

  .task-content {
    padding: 20px;
  }

  .task-form {
    margin: 0;
  }

  .task-window {
    margin: 20px 0;
  }

  .task-form .label img {
    display: block;
  }

  .task-window.default {
    width: 380px;
    height: 629px;
    opacity: 1;
  }

  .task-window.advanced-settings {
    width: 386px;
    height: 552px;
    opacity: 1;
  }

  .task-window.calendar {
    width: 717px;
    height: 608px;
    opacity: 1;
  }
}

:root {
  --error-msg-background: #FAF0F0;
  --error-msg-color: #F46C6C;
  --task-settings-border: #E7EAEE;
  --task-settings-window: white;
}

[data-theme='dark'] {
  --error-msg-background: #1A1F32;
  --error-msg-color: #F46C6C;
  --task-settings-border: #E7EAEE33;
  --task-settings-window: white;
}