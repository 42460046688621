/* Tariffs */
.tariffs-background {
  background-color: var(--uneven-box-background);
}

.tariffs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 55px 0;
  width: 90%;
}

.tariffs .label {
  font-size: 55px;
  font-weight: 500;
  line-height: 60px;
  margin-top: 10px;
  width: 560px;
  color: var(--text-color);
}

.tariffs-cards {
  display: flex;
  margin-top: 35px;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
}

.tariff-card.default {
  padding: 20px;
  background-color: var(--tariff-card-background);
  color: var(--text-color);
  border: 1px solid var(--tariff-card-border);
  border-radius: 15px;
  width: calc(33% - 7px);
  fill: #2D8EFF;
  display: flex;
  flex-direction: column;
}

.tariff-card.biggest {
  padding: 20px;
  background-color: #2D8EFF;
  color: white;
  border-radius: 15px;
  width: calc(33% - 7px);
  fill: white;
  display: flex;
  flex-direction: column;
}

.tariff-card-type {
  opacity: 0.8;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.tariff-card-price {
  font-size: 55px;
  font-weight: 400;
  line-height: 70px;
  margin-top: 15px;
}

.tariff-card-price span {
  font-size: 16px;
  line-height: 20px;
}

.tariff-card-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.8;
  margin-top: 10px;
}

.tariff-card-buy-button {
  background-color: white;
  border: 1px solid #E7EAEE;
  color: #2D8EFF;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  width: 100%;
  text-wrap: nowrap;
  text-decoration: none;
}

.tariff-card-buy-button:hover {
  background-color: #2D8EFF;
  border: 1px solid #E7EAEE;
  color: white;
}

.task-card-advantage-item {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 15px;
}

.task-card-advantage-item svg {
  height: 20px;
  width: 20px;
}

@media (max-width: 1100px) {
  .tariffs .label {
    font-size: 40px;
    font-weight: 500;
    line-height: 45px;
    width: auto;
  }
}

@media (max-width: 900px) {
  .tariffs-cards {
    flex-direction: column;
    align-items: center;
  }

  .tariff-card {
    width: 100% !important;
  }
}