.profile-settings-info-item {
  border: 1px solid var(--borders-color);
  border-radius: 20px;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.profile-settings-input-fields {
  display: flex;
  gap: 10px;
}

.add-channel-btn {
  padding: 10px;
  background-color: #2D8EFF;
  color: white;
  border: none;
  cursor: pointer;
}

.add-channel-btn:hover {
  background-color: #2266cc;
}
