.profile-container {
  text-align: left;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  flex: 1;
  /* padding: 0px 100px; */
}

.profile-container h1 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 35px;
  color: var(--text-color);
}

.cards-section {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 20px;
}

.profile-card {
  background-color: var(--background-color);
  background-image: var(--profile-card-background);
  background-size: 176% 200%;
  background-repeat: no-repeat;
  background-position: -114px -18px;
  color: var(--text-color);
  border-radius: 20px;
  text-align: left;
  border: 2px solid var(--borders-color);
  flex: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: 135px;
  align-items: center;
  padding: 15px 0;
  text-decoration: none;
}

.profile-card:hover {
  background-color: var(--profile-card-hover);
  border-color: var(--profile-card-hover);
}

.profile-card:hover .profile-card-content {
  color: white;
}

.profile-card-content {
  display: block;
  margin: 0 15px;
}

.profile-card-text {
  margin-left: 10px;
}

.profile-card-icon-container {
  position: relative;
  display: flex;
}

.profile-card-icon1 {
  width: 45px;
  height: 45px;
}

.profile-card-icon2 {
  justify-content: center;
  margin-right: 20px;
  width: 100%;
}

.profile-card-content h3 {
  font-size: 18px;
  margin: 0;
  white-space: nowrap;
}

.profile-card-content p {
  margin: 5px 0 0 0;
  color: inherit;
}

.profile-card-button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

@-moz-document url-prefix() {
  .subscription-banner h2 {
    font-size: 45px;
  }
}

@media (max-width: 1280px) {
  .profile-container {
    max-width: calc(100% - 40px);
  }
}

@media (max-width: 1000px) {
  .cards-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-card {
    margin-bottom: 10px;
    width: 100%;
  }
}

@media (max-width: 800px) {
  .profile-header h2 {
    font-size: 22px;
  }

  .profile-card-icon2 {
    margin-right: 8px;
  }
}

:root {
  --profile-info-block: #2D8EFF;
  --profile-card-background: url('@assets/profile/profileCardBackgroundLight.svg');
  --profile-card-hover: #2D8EFF;
  --detail-item-background: white;
  --detail-item-description-text: #555;
}

[data-theme='dark'] {
  --profile-info-block: #2065ba;
  --profile-card-background: url('@assets/profile/profileCardBackgroundDark.svg');
  --profile-card-hover: #2D8EFFB2;
  --detail-item-background: rgba(255, 255, 255, 0.10);
  --detail-item-description-text: rgba(255, 255, 255, 0.8);
}
