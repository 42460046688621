/* How It Work */
.how-it-work {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 65px 0;
  height: auto;
}

.how-it-work .label {
  font-size: 55px;
  font-weight: 500;
  line-height: 60px;
  max-width: 690px;
  margin-top: 10px;
  color: var(--text-color);
}

.how-it-work-menus {
  display: flex;
  justify-content: space-between;
  background-color: var(--how-it-work-menus-background);
  border-radius: 15px;
  padding: 10px;
  margin-top: 15px;
  width: 100%;
  max-width: 770px;
  flex-wrap: wrap;
}

.how-it-work-menu {
  color: var(--text-color);
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 9px 15px;
  cursor: pointer;
}

.how-it-work-menu.active {
  color: #2D8EFF;
  opacity: 1;
  background-color: var(--how-it-work-menu-background-active);
}

.how-it-work-container {
  border: 1px solid var(--how-it-work-container-border);
  width: 100%;
  border-radius: 25px;
  display: flex;
  margin-top: 25px;
}

.how-it-work-main {
  margin: 20px;
  flex: 1;
  width: 50%;
}

.how-it-work-main .label {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: var(--text-color);
}

.how-it-work-main .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--text-color);
}

.how-it-work-main .link {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #2D8EFF;
  margin-top: 115px;
  display: flex;
  cursor: pointer;
  gap: 10px;
}

.how-it-work-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.how-it-work-image img {
  max-width: 100%;
  height: 99%;
}

@media (max-width: 1100px) {
  .how-it-work .label {
    font-size: 45px;
    font-weight: 500;
    line-height: 50px;
  }

  .how-it-work-main .label {
    font-size: 28px;
    font-weight: 500;
    line-height: 35px;
  }

  .how-it-work-main .link {
    margin-top: 15px;
  }
}


@media (max-width: 950px) {
  .how-it-work-container {
    flex-direction: column;
  }

  .how-it-work-main,
  .how-it-work-image {
    width: auto;
  }
}

@media (max-width: 800px) {
  .how-it-work-container {
    flex-direction: column;
  }
}