.task-card {
  background: var(--task-card-background);
  border-radius: 20px;
  border: 1px solid var(--task-card-border);
  padding: 10px;
    width: calc(25% - 12px);
  min-width: 280px;
  height: min-content;
  display: flex;
  flex-direction: column;
  position: relative; /* Добавлено для корректного позиционирования псевдоэлемента */
  overflow: hidden; /* Чтобы фон не выходил за границы карточки */
}

.task-card.unactive::after {
  content: ""; /* Необходим для создания псевдоэлемента */
  position: absolute; /* Абсолютное позиционирование */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--task-card-unactive); /* Черный полупрозрачный фон */
  z-index: 1; /* Чтобы фон был поверх содержимого */
}

/*.task-card.unactive > * {*/
/*  position: relative; !* Поднятие содержимого поверх затемнения *!*/
/*  z-index: 2;*/
/*}*/

.task-card.expanded {
  height: 368px;
}

.task-card-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 135px;
  background-image: var(--task-card-header-background);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 10px;
}

.task-card-header .upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-tooltip {
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  padding: 0;
}

.menu-tooltip button {
  color: black;
  border: 1px solid #ccc;
  width: 100%;
}

.task-icon {
  width: 24px;
  height: 24px;
}

.edit-menu-btn {
  cursor: pointer;
  margin-left: 5px;
}

.task-card-edit-menu {
  width: 140px;
  height: min-content;
  border-radius: 0 10px 10px 10px;
  position: absolute;
  top: 25px;
  left: 15px;
  z-index: 500;
  background-color: var(--task-card-edit-menu);
}

.task-card-edit-menu-item {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
}

.task-card-edit-menu-item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.task-card-edit-menu-item:hover {
  background-color: #00000005;
}

.task-due-date {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: var(--text-color);
  white-space: nowrap;
}

.task-due-date img {
  margin-right: 5px;
}

.status-progress-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.task-title {
  display: flex;
  font-size: 18px;
  text-align: left;
  align-items: center;
  line-height: 1.2;
}

.task-type-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  color: white;
  background-color: #2D8EFF;
  border-radius: 10px;
  margin-right: 10px;
}

.task-title img {
  margin-right: 10px;
}

.task-id {
  display: flex;
  gap: 10px;
  align-items: center;
  width: min-content;
  background: var(--task-id);
  backdrop-filter: blur(1px);
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.task-status {
  font-size: 14px;
  font-weight: 400;
}

.task-progress {
  flex: 1;
  text-align: right;
}

.task-progress-bar {
  background: #2D8EFF1A;
  border-radius: 8px;
  overflow: hidden;
  height: 3px;
  width: 100%;
  margin-bottom: 6px;
}

.progress-line {
  background: #2D8EFF;
  height: 100%;
}

.progress-line.success {
  background: #61C577;
}

.progress-info {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.progress-info img {
  margin-left: 5px;
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  visibility: visible;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1000;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  width: max-content;
  max-width: 200px;
}

#my-tooltip {
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 145px;
}

.task-card-remain-time {
  background-color: #fff;
}

.custom-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.task-statistics {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
  text-align: left;
  margin-bottom: 4px;
  position: relative;
}

.task-statistics .show-progress {
  color: #2d8EFF;
  text-decoration: underline;
  cursor: pointer;
}

.task-statistics .task-expected {
  background-color: var(--telegram-post-background);
  border: none;
  width: max-content;
  max-width: 270px;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 30px;
  right: 0;
  text-align: left;
  z-index: 975;
  color: var(--telegram-post-text-color);
  border-radius: 8px 0 8px 8px;
}

.task-statistics .task-expected .my-row {
  display: flex;
  align-items: center;
}

.task-statistics .task-expected .my-row .time {
  font-size: 12px;
  font-weight: 400;
  color: var(--task-progress-time);
}

.task-statistics .task-expected .my-row img {
  margin-left: 6px;
}

.highlight-text {
  color: #2D8EFF;
}

.task-card-footer .divider {
  height: 1px;
  background-color: var(--task-card-border);
  margin: 0 0 5px 0;
  border: none;
  opacity: 1;
}

.additional-statistics {
  font-size: 14px;
  color: var(--text-color);
  text-align: left;
  margin-top: 7px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 3px;
}

.additional-statistics .additional-statistic {
  width: min-content;
  height: 31px;
  padding: 8px 10px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--additional-statistic-background);
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.additional-statistics .additional-statistic .text {
  text-wrap: nowrap;
}

.calendar {
  color: #666;
  justify-content: center;
}

.task-card-footer {
  text-align: left;
}

.details-button {
  background: none;
  color: #3b82f6;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}

.details-button:hover {
  text-decoration: underline;
}

@media (max-width: 1240px) {
  .task-card {
    width: calc(33.33% - 10px);
  }
}

@media (max-width: 930px) {
  .task-card {
    width: calc(50% - 8px);
  }
}

@media (max-width: 800px) {

  .task-card {
    min-width: 189px;
  }

  /*.task-title {*/
  /*  font-size: 14px;*/
  /*}*/
}


@media (max-width: 429px) {
  .task-title img {
    margin-right: 0;
  }
}

@media (max-width: 700px) {
  .task-card {
    width: 100%;
  }
}

:root {
  --task-card-header-background: url('@assets/tasks/taskCardBackground.svg');
  --task-card-edit-menu: white;
  --task-due-date: #666;
  --additional-statistic-background: #F5F5F5;
  --task-id: rgba(0, 0, 0, 0.05);
  --task-progress-time: rgba(0, 0, 0, 0.2);
  --task-card-unactive: rgba(255, 255, 255, 0.5);
}

[data-theme='dark'] {
  --task-card-header-background: url('@assets/tasks/taskCardBackgroundDark.svg');
  --task-card-edit-menu: #484C5B;
  --task-due-date: white;
  --additional-statistic-background: rgba(245, 245, 245, 0.10);
  --task-id: rgba(255, 255, 255, 0.1);
  --task-progress-time: rgba(255, 255, 255, 0.2);
  --task-card-unactive: rgba(0, 0, 0, 0.5);
}