.task-content {
  display: flex;
  width: 100%;
  padding: 10px 8px 10px 20px;
  opacity: 0;
  animation: fadeIn 0.2s ease-in forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    /*transform: translateY(20px);*/
  }
  100% {
    opacity: 1;
    /*transform: translateY(0);*/
  }
}

.task-form {
  flex: 1;
  margin-right: 30px;
  width: 350px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.task-form .label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.task-form .label h2 {
  width: 239px;
  font-size: 28px;
  font-weight: 600;
}

.task-form .label img {
  display: none;
  margin-right: 8px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.type-task-buttons-container {
  display: flex;
  justify-content: left;
  gap: 5px;
}

.type-task-button {
  flex: 1 1;
  width: 100%;
  text-align: center;
  padding: 0 30px;
  border-radius: 10px;
  color: var(--color-buttons);
  background-color: var(--task-card-background);
  border: 1px solid var(--color-buttons);
  height: 38px;
  margin-bottom: 15px;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.type-task-button.active {
  background-color: var(--color-buttons);
  color: white;
  border: none;
}

.task-form-count-bots {
  display: flex;
  align-items: center;
  background-color: #2D8EFF1A;
  width: 100%;
  height: 71px;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.left-side-bots {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bot-info-container {
  display: flex;
  align-items: center;
}

.bot-icon {
  margin-right: 8px;
  fill: var(--default-icon);
  width: 20px;
  height: 20px;
}

.bot-count {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.task-form-data {
  display: flex;
  flex-direction: column;
}

.task-form-data-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

.task-form-data-row>* {
  flex: 1;
  min-width: 0;
}

.task-form .input-container {
  margin-bottom: 15px;
}

.task-form-btn-box {
  margin-top: 10px;
  display: flex;
  gap: 10px;
}

.task-form-submit-button {
  width: 100%;
  padding: 10px;
  background-color: var(--color-buttons);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.task-form-submit-button.pending {
  opacity: 0.6;
}

.advanced-setting-btn {
  width: 45px;
  padding: 13px;
  border-radius: 10px;
  border: 1px solid #2D8EFF;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.reactions-popup {
  position: absolute;
  top: 234px;
  width: 198px;
  height: 178px;
  background-color: var(--select-field-option-background);
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 8px 8px 8px 0;
}

.reaction-item {
  width: 18px;
  height: 18px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.reaction-item.selected {
  position: relative;
}

.reaction-item.selected .reaction-selected {
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #e0e0e0;
  border-radius: 50%;
  top: -4px;
  left: -4px;
  z-index: -1;
}

.reactions-popup::-webkit-scrollbar {
  width: 8px;
}

.reactions-popup::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

.reactions-popup::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

.reactions-popup::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tooltip-bots-info {
  position: absolute;
  top: -108px;
  left: 102px;
  transform: translateX(-50%);
  width: 192px;
  height: 118px;
  background-color: var(--task-card-background);
  border: 1px solid var(--input-field-borders);
  padding: 8px;
  border-radius: 10px 10px 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  white-space: nowrap;
}

.tooltip-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 14px;
  color: var(--text-color);
}

.tooltip-icon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
}

.tooltip-link {
  color: #2D8EFF;
  /*text-decoration: none;*/
  font-weight: 400;
  text-decoration: underline;
}

.right-side-bots {
  position: relative;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--loading-screen-background);
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid var(--spinner-border);
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 800px) {
  .tooltip-bots-info {
    border-radius: 10px 10px 0 10px;
    left: -85px;
  }

  .task-form .label img {
    display: block;
  }
}


:root {
  --loading-screen-background: rgba(255, 255, 255, 0.8);
  --spinner-border: rgba(0, 0, 0, 0.1);
}

[data-theme='dark'] {
  --loading-screen-background: rgba(0, 0, 0, 0.8);
  --spinner-border: rgba(255, 255, 255, 0.1);
}