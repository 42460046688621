.partner-code {
  width: 60%;
  border: 1px solid var(--partnership-borders);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
}

.partner-code .steps-container {
  display: flex;
  justify-content: space-between;
}

.partner-code .steps-container .step {
  display: flex;
  gap: 15px;
  width: 254px;
}

.partner-code .steps-container .step .step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  background-color: #2D8EFF;
  color: white;
}

.partner-code .steps-container .step .step-label {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.partner-code .steps-container .step .step-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.8;
  margin-top: 5px;
}

.partner-code .code-container {
  display: flex;
  padding: 10px 20px;
  background-color: var(--referal-code-background);
  border-radius: 15px;
  gap: 5px;
}

.partner-code .code-container .copy-btn,
.partner-code .code-container .share-btn {
  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: 10px;
  background-color: #2D8EFF;
  color: white;
  padding: 13px 24px;
  height: 45px;
}

.partner-code .code-container .copy-btn {
  margin-left: auto;
}

.copy-tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  animation: fadeInOut 2s forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@media (max-width: 1150px) {
  .partner-code {
    width: 100%;
  }
}



@media (max-width: 800px) {
  .partner-code .steps-container {
    flex-direction: column;
  }

  .partner-code .steps-container .step {
    width: auto;
  }
}