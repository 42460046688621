.tasks-pagination {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 15px 5px;
  font-size: 16px;
  position: fixed;
  bottom: 0;
  width: 1240px;
  background-color: var(--dropdown-menu-color);
  border-radius: 20px 20px 0 0;
  box-shadow: var(--tasks-pagination-shadow);
  z-index: 499;
}

.tasks-pagination select {
  width: 217px;
  height: 35px;
  border: 1px solid var(--tasks-pagination-shadow-select);
  border-radius: 10px;
  color: var(--text-color);
  background-color: transparent;
}

.tasks-pagination select option {
  background-color: var(--dropdown-menu-color);
}

.pages-container {
  display: flex;
  gap: 5px;
  align-items: center;
}

.pages-container .triple-points,
.pages-container .page {
  border-radius: 50%;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: var(--tasks-pagintaion-page-text-color);
}

.pages-container .page:hover {
  background: var(--tasks-pagination-page-hover);
}

.pages-container .page.active {
  color: #2D8EFF;
  background: #2D8EFF1A;
}

@media (max-width: 555px) {
  .tasks-pagination select {
    width: auto;
  }

  .pages-container {
    gap: 0;
  }
}

:root {
  --tasks-pagination-shadow: 0px 4px 10px 1px #0000000D;
  --tasks-pagination-shadow-select: #E7EAEE;
  --tasks-pagintaion-page-text-color: #00000033;
  --tasks-pagination-page-hover: #F5F5F5;
}

[data-theme='dark'] {
  --tasks-pagination-shadow: 0px 4px 15px 1px #FFFFFF0D;
  --tasks-pagination-shadow-select: #E7EAEE33;
  --tasks-pagintaion-page-text-color: #FFFFFF33;
  --tasks-pagination-page-hover: #F5F5F51A;
}