.partnership {
  width: 1240px;
  margin: 0 auto;
  padding: 35px 0 65px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.partnership-row {
  display: flex;
  gap: 10px;
}

.partnership .label {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  color: var(--text-color);
}

.partnership .row {
  display: flex;
  gap: 10px;
}

@media (max-width: 1280px) {
  .partnership {
    max-width: calc(100% - 40px);
  }
}

@media (max-width: 1150px) {
  .partnership-row {
    flex-direction: column;
    gap: 15px;
  } 
}

:root {
  --partnership-borders: #EFEFEF;
  --referal-code-background: #F0F3F5;
}

[data-theme='dark'] {
  --partnership-borders: rgba(239, 239, 239, 0.20);
  --referal-code-background: #F0F3F51A;
}