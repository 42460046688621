.about-service-cards,
.about-service-1 {
  display: flex;
  flex-direction: column;
  width: 1240px;
  margin: 0 auto;
}

.about-service-1 .label {
  color: var(--text-color);
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  width: 550px;
}

.about-service-1 .description {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  opacity: 0.8;
  width: 610px;
}

.services-advantages-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 45px;
  width: 100%;
}

.services-advantages-container .service-advantage-block {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 24%;
  height: 70px;
  border-radius: 15px;
  background: var(--service-advantage-block-background);
  padding: 22px 20px;
}

.services-advantages-container .service-advantage-block .label {
  color: var(--text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-wrap: nowrap;
}

.about-service-1 {
  gap: 15px;
}


@media (max-width: 1280px) {

  .about-service-cards,
  .about-service-1 {
    width: calc(100% - 40px);
  }

  .services-advantages-container .service-advantage-block {
    width: 48%;
  }
}


@media (max-width: 655px) {

  .services-advantages-container .service-advantage-block {
    width: 100%;
  }

  .about-service-1 .label {
    font-size: 35px;
    line-height: 40px;
    width: 100%;
  }

  .about-service-1 .description {
    font-size: 14px;
    line-height: 22px;
    width: 100%;
  }
}

:root {
  --service-advantage-block-background: #F9FAFB;
}

[data-theme='dark'] {
  --service-advantage-block-background: #1A1F32;
}