.profile-settings,
.wallet-settings {
  max-width: 1240px;
  width: calc(100% - 40px);
  margin: 0 auto;
  height: auto;
}

.profile-setting-container {
  display: flex;
  gap: 20px;
}

.profile-settings-links-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 232px;
}

.profile-settings-links-item {
  border: 1px solid var(--borders-color);
  border-radius: 15px;
  width: 100%;
  padding: 5px;
  cursor: pointer;
}

.profile-settings-link {
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  text-decoration: none;
}

.profile-settings-link.active {
  background-color: #2D8EFF1A;
}

.profile-settings-link .link {
  font-weight: 400;
  color: var(--text-color);
}

.angle {
  margin-left: auto;
}

.profile-settings-info-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 80%;
  height: auto;
}

@media (max-width: 900px) {
  .profile-settings {
    height: auto;
  }

  .profile-setting-container {
    flex-direction: column;
  }

  .profile-settings-links-container,
  .profile-settings-info-container {
    width: 100%;
  }
}
