.email-stage {
  flex: 1;
}

.reset-back-btn {
  border: 1px solid #E7EAEE !important;
  background-color: white !important;
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 500;
  margin-bottom: auto;
}

.auth-form .steps-container {
  display: flex;
  gap: 7px;
  margin-top: auto;
  margin-bottom: 5px;
}

.step-item {
  height: 4px;
  width: 100%;
  background-color: #2D8EFF33;
  border-radius: 12px;
}

.step-item.active {
  background-color: #2D8EFF;
}

.code-field-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Высота подпрыгивания */
  }
}

.code-field-item {
  width: 86px;
  height: 86px;
  border-radius: 10px;
  border: 1px solid #DEDEDE;
  pointer-events: auto;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 55px;
  transition: background-color 0.5s ease, border 0.5s ease;
}

.code-field-item.active {
  transform: translateY(-5px);
  transition: transform 0.2s ease;
}

.code-field-container.success .code-field-item {
  border-color: #28a745; /* Зеленая рамка */
  /*background-color: #d4edda; !* Светло-зеленый фон *!*/
  color: #155724; /* Текст зеленого цвета */
}

/* Эффект подпрыгивания */
.code-field-container.bouncing .code-field-item {
  animation: bounce 0.6s ease infinite;
}

.code-field-container.bouncing .code-field-item:nth-child(1) {
  animation-delay: 0s;
}

.code-field-container.bouncing .code-field-item:nth-child(2) {
  animation-delay: 0.1s;
}

.code-field-container.bouncing .code-field-item:nth-child(3) {
  animation-delay: 0.2s;
}

.code-field-container.bouncing .code-field-item:nth-child(4) {
  animation-delay: 0.3s;
}

/* Ошибка: поле красное */
.code-field-container.error .code-field-item {
  border-color: #ff4d4f !important;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Анимация резкого дрожания */
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
}

.code-field-container.shaking {
  margin-top: 0;
  animation: shake 0.25s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.hidden-input {
  position: absolute; /* Абсолютное позиционирование */
  top: 0; /* Совпадает с верхней частью контейнера */
  left: 0; /* Совпадает с левой частью контейнера */
  width: 50% !important; /* Полностью покрывает ширину контейнера */
  height: 90%; /* Полностью покрывает высоту контейнера */
  opacity: 0; /* Полностью прозрачное */
  z-index: 10; /* Размещено выше остальных элементов */
  border: none; /* Убирает границы */
  outline: none; /* Убирает обводку */
  background: none; /* Убирает фон */
  caret-color: transparent; /* Убирает курсор */
}


@media (max-width: 768px) {
  .code-field-item {
    width: 80px;
    height: 80px;
  }

  .hidden-input {
    width: 100% !important; /* Полностью покрывает ширину контейнера */
    height: 90%; /* Полностью покрывает высоту контейнера */
  }
}