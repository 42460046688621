.partnership-how-it-work {
  display: flex;
  flex-direction: column;
  width: 1240px;
  margin: 65px auto 0 auto;
  gap: 20px;
}

.partnership-how-it-work .label {
  color: var(--text-color);
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.partnership-how-it-work .steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner-step {
  width: 1000px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
}

.partner-step.reverse {
  flex-direction: row-reverse;
  border-top: 1px dashed var(--partnership-how-it-work-step-border);
  border-bottom: 1px dashed var(--partnership-how-it-work-step-border);
}

.step-counter {
  color: #FFF;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.partner-step-specification {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 40%;
}

.partner-step-specification .label {
  color: var(--text-color);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.partner-step-specification .description {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.partner-step-image {
  border-radius: 15px;
  background: var(--partnership-how-it-work-background);
  width: 45%;
  display: flex;
  height: 204px;
  align-items: flex-end;
  justify-content: center;
  margin-left: auto;
}

.partner-step.reverse .partner-step-image {
  margin-right: auto;
  margin-left: 0;
}

.create-account-btn {
  display: inline-flex;
  padding: 15px 45px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  background: #2D8EFF;
  color: white;
  border: none;
  margin-top: 35px;
  width: 65%;
  text-wrap: nowrap;
}




@media (max-width: 1280px) {
  .partnership-how-it-work {
    width: calc(100% - 40px);
  }

  .partner-step {
    width: 100%;
  }
}

@media (max-width: 800px) {

  .partner-step,
  .partner-step.reverse {
    flex-direction: column;
  }

  .partnership-how-it-work .label {
    font-size: 35px;
    line-height: 40px;
  }

  .partner-step-specification {
    width: 100%;
    align-items: center;
  }

  .partner-step-specification .label {
    text-align: center;
    font-size: 22px;
  }

  .partner-step-specification .description {
    text-align: center;
  }

  .partner-step-image {
    margin-left: 0;
    width: 100%;
  }

  .partner-step-image svg {}
}

:root {
  --partnership-how-it-work-background: #F2F2F2;
  --partnership-how-it-work-step-border: #E9E9E9;
}

[data-theme='dark'] {
  --partnership-how-it-work-background: #F2F2F21A;
  --partnership-how-it-work-step-border: #2F3344;
}