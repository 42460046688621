.task-settings {
  padding: 20px;
  width: 100%;
  opacity: 0;
  animation: fadeIn 0.2s ease-in forwards;
}

.task-settings-header {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 15px;
}

.task-settings-header h3 {
  font-size: 28px;
  font-weight: bold;
}

.task-settings-fields {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.task-settings-field {
  flex: 1;
}

.task-settings-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.task-settings-field select,
.task-settings-field input {
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.chart-content {
  height: 300px;
  margin-top: 20px;
  width: 500px;
}

.task-settings-footer {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.reset-button,
.save-button {
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.reset-button {
  background-color: var(--task-card-background);
  color: var(--color-buttons);
  border: 1px solid var(--color-buttons)
}

.save-button {
  background-color: var(--color-buttons);
  color: white;
}

@media (max-width: 800px) {
  .chart-content {
    height: 167px;
    width: 331px;
    margin-right: 15px;
  }
}

@media (max-width: 700px) {
  .task-settings-fields {
    display: block;
  }

  /*.chart-content {*/
  /*  height: 275px;*/
  /*}*/
}

@media (max-width: 580px) {
  .task-settings-footer {
    display: block;
  }

  .reset-button {
    margin-bottom: 10px;
  }
}