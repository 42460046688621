/* Reviews */
.reviews {
  display: flex;
  gap: 24px;
  padding: 35px 0;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 90%;
  min-height: 255px;

  /* Убираем горизонтальную полосу прокрутки */
  scrollbar-width: none;
  /* Для Firefox */
  -ms-overflow-style: none;
  /* Для IE и Edge */
}

.reviews::-webkit-scrollbar {
  display: none;
  /* Для Chrome, Safari и Opera */
}

.reviews .review-item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 292px;
  vertical-align: top;
  flex-shrink: 0;
  box-sizing: border-box;
  height: min-content;
}

.reviews .review-item .review-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  font-style: italic;
  white-space: normal;
  word-wrap: break-word;
}

.reviews .review-item .review-user {
  display: flex;
}

.reviews .review-item .review-user .review-user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
}

.reviews .review-item .review-user .review-user-text {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.reviews .review-item .review-user .review-user-text .review-username {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: var(--text-color);
}

.reviews .review-item .review-user .review-user-text .review-user-post {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color);
  opacity: 0.6;
  line-height: 15px;
}

@media (max-width: 768px) {
  .reviews {
    min-height: 305px;
  }

  .reviews .review-item {
    width: 220px;
  }
}