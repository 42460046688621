.hamburger-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  fill: #2D8EFF;
}

.hamburger-button svg {
  width: 30px;
  height: 30px;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  z-index: 1000;
  overflow: hidden; /* Отключаем прокрутку внутри меню */
}

/*.mobile-menu {*/
/*  display: none;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: var(--background-color);*/
/*  z-index: 1000;*/
/*  !*position: relative;*!*/
/*  overflow: hidden;*/
/*}*/

.menu-refs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  overflow: hidden;
}

.mobile-link {
  margin: 10px 0;
  text-decoration: none;
  color: var(--text-color);
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
}

.close-button {
  /* position: absolute;
  top: 20px;
  right: 20px; */
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.close-button img {
  width: 30px;
  height: 30px;
}

.profile-section {
  display: flex;
  align-items: center;
  background-color: #2D8EFF1A;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  text-decoration: none;
  color: var(--text-color);
}

.profile-section.mobile {
  margin-bottom: 24px;
}

.menu-theme-toggle {
  width: 35px;
  height: 35px;
  justify-content: right;
  margin-left: 15px;
  cursor: pointer;
}

.auth-buttons-background {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(45, 142, 255, 0.1);
  padding: 10px 0;
}

.auth-buttons {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(45, 142, 255, 0.1);
  padding: 10px;
}

/* .mobile-menu {
  display: flex;
} */

.mobile-header-title {
  position: absolute;
  top: 33px;
  left: 30px;
}

.header-buttons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.mobile-button {
  padding: 13px 35px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 15px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 2px solid var(--color-buttons);
  flex: 1;
  max-width: 45%;
}

.mobile-button.register-button {
  background-color: var(--color-buttons);
  color: white;
  border: 2px solid var(--color-buttons);
  flex: 1;
  max-width: 45%;
}


@media (max-width: 1050px) {
  .header-center,
  .header-right {
    display: none !important;
  }

  .hamburger-button {
    display: block !important;
  }

  .mobile-menu {
    display: flex !important;
  }
}

@media (max-width: 800px) {
  .header::after {
    width: 100% !important;
  }
}