.tasks-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.tasks-title h1 {
  display: flex;
  justify-content: left;
  gap: 10px;
  font-weight: 600;
}

.tasks-type {
  border: 1px solid var(--task-type-border);
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #7F7F7F;
  background-color: var(--background-color);
}

.tasks-type.active {
  border: 1px solid #2D8EFF;
  color: #2D8EFF;
}

.tasks-buttons {
  display: flex;
}

.tasks-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2D8EFF;
  border-radius: 10px;
  background-color: var(--background-color);
  border: 2px solid var(--color-buttons);
  margin-left: 15px;
  text-wrap: nowrap;
  padding: 12px 35px;
  line-height: 20px;
}

.square-box {
  padding: 15px;
}

@media (max-width: 800px) {
  .tasks-buttons {
    gap: 5px;
  }
}

@media (max-width: 450px) {
  .tasks-buttons {
    justify-content: center;
    flex-wrap: wrap;
  }

  .tasks-buttons button {
    width: calc(50% - 5px) !important;
  }
}