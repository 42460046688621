.tasks {
  padding: 28px 0;
  text-align: center;
  width: 1240px;
  min-height: 800px;
  margin: 0 auto;
  flex: 1;
}

.tasks-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.tasks-buttons .search-bar {
  border: 1px solid var(--task-type-border);
  padding: 12px 15px;
  border-radius: 10px;
  width: 300px;
}

.tasks-buttons .search-window .search-input,
.tasks-buttons .search-bar .search-input {
  width: 100%;
  font-size: 14px;
  outline: none;
  border: none;
  background: transparent;
  color: var(--text-color);
}

.tasks-buttons .search-bar.mobile {
  display: none;
}

.search-input::placeholder {
  color: #B5B5B5;
}

.search-window {
  display: none;
}

.search-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-tasks {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 100px;
  margin-bottom: 50px;
}

.empty-tasks img {
  width: 419px;
  height: 300px;
}

.empty-tasks .label {
  font-size: 26px;
  font-weight: 600;
  line-height: 33px;
}

.empty-tasks .description {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.empty-tasks button {
  border: none;
  border-radius: 10px;
  color: white;
  background-color: #2D8EFF;
  width: 180px;
  height: 45px;
  margin-top: 5px;
}

@media (max-width: 1280px) {
  .tasks,
  .tasks-pagination {
    max-width: calc(100% - 40px);
  }
}

@media (max-width: 1000px) {
  .tasks-buttons .search-bar.desktop {
    display: none;
  }

  .tasks-buttons .search-bar.mobile {
    display: flex;
  }

  .tasks-buttons .search-bar {
    border: 2px solid var(--color-buttons);
    width: 45px;
    height: 45px;
  }

  .search-window {
    display: flex;
    width: calc(100% - 40px);
    background-color: var(--filter-window-background);
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    padding: 10px 15px;
    position: absolute;
    left: 20px;
    z-index: 500;
  }

  .square-box,
  .filter-btn,
  .sort-btn {
    display: flex;
    width: 45px;
    height: 45px;
    padding: 0 !important;
  }
}

@media (max-width: 800px) {
  .tasks {
    padding: 20px 0;
  }

  .empty-tasks img {
    width: 70%;
    height: auto;
  }

  .tasks-grid {
    gap: 12px;
  }

  .desktop-btn {
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .tasks-buttons button {
    margin-left: 0;
  }

  .square-box,
  .filter-btn,
  .sort-btn {
    width: 35px;
    height: 35px;
  }

  .tasks-buttons .search-bar {
    width: 35px;
    height: 35px;
  }
}

:root {
  --task-type-border: #E7EAEE;
}

[data-theme='dark'] {
  --task-type-border: #E7EAEE80;
}
