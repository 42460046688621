.footer {
  background-color: var(--background-color);
  padding-top: 50px;
  text-align: center;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
}

.footer-divider {
  height: 1px;
  background-color: var(--borders-color);
  margin-bottom: 20px;
  border: none;
}

.footer-section {
  text-align: center;
  margin-bottom: 20px;
}

.footer-button {
  /* width: 199px;
  height: 46px; */
  background-color: var(--background-color);
  color: #2D8EFF;
  border: 1px solid #2D8EFF;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
  text-align: center;
  text-wrap: nowrap;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 10px;
}

.footer-column {
  text-align: left;
  width: auto;
  max-width: 200px;
  margin-bottom: 20px;
}

.footer-column h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--text-color)
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li a {
  margin-bottom: 5px;
  color: var(--links-color);
  text-decoration: none;
}


@media (max-width: 1280px) {
  .footer {
    max-width: calc(100% - 40px);
  }
}

@media (max-width: 800px) {
  .footer-column {
    width: calc(50% - 10px);
  }
}
