.partner-balance {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  width: 40%;
  border-radius: 10px;
  padding: 20px;
  background-color: #2D8EFF;
  color: white;
  min-width: 311px;
}

.partner-balance .balance {
  font-size: 40px;
  font-weight: 500;
  line-height: 51px;
}

.partner-balance .course {
  font-weight: 400;
}

.partner-balance .withdraw {
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 10px;
  background-color: white;
  color: #2D8EFF;
}

@media (max-width: 800px) {
  .partner-balance {
    width: 100%;
  }
}