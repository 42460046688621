.operations-table {
  flex: 1 1;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid var(--partnership-borders);
  /* max-width: 800px; */
  width: 50%;
}

.operations-table h2 {
  margin-bottom: 20px;
}

.operations-table table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
}

.operations-table th,
.operations-table td {
  padding: 15px;
  text-align: left;
  background-color: var(--partner-operation-table-background);
}

.operations-table tbody tr {
  border-bottom: 1px solid #f0f0f0;
}

.operations-table .operation-table-row {
  display: flex;
  gap: 5px;
}

.operations-table .icon {
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}

.operations-table .icon {
  width: 24px;
  height: 24px;
  display: inline-flex;
  background-color: #2D8EFF;
  border-radius: 50%;
}

.operations-table .usd-amount,
.operations-table .rub-amount {
  display: block;
  color: var(--text-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;
}

.operations-table .rub-amount {
  opacity: 0.5;
}

.operations-table .show-all {
  display: block;
  margin-top: 20px;
  text-align: center;
  color: #007bff;
  font-weight: 500;
}


@media (max-width: 1150px) {
  .operations-table {
    width: 100%;
    max-width: 100%;
  }
}

:root {
  --partner-operation-table-background: rgba(45, 142, 255, 0.05);
}

[data-theme='dark'] {
  --partner-operation-table-background: rgba(45, 142, 255, 0.1);
}