.sort-window {
  position: absolute;
  width: 190px;
  top: 185px;
  right: 82px;
  border-radius: 10px;
  background: var(--sort-window-background);
  color: var(--text-color);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 950;
  padding: 5px;
}

.sort-window .sort-item {
  padding: 10px;
  text-align: left;
  color: var(--text-color);
  cursor: pointer;
}

.sort-window .sort-item.active {
  background-color: var(--task-type-border);
}

.sort-window .sort-item:not(:last-child) {
  border-bottom: 1px solid #d9d9d949;
}

.sort-window .sort-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.sort-window .sort-item:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.sort-window .sort-item.active {
  background: #d9d9d949;
}

:root {
  --sort-window-background: white;
}

[data-theme='dark'] {
  --sort-window-background: #484C5B;
}