.about-news-background {
  width: 100%;
  background: var(--about-news-background);
  margin-top: 56px;
  overflow: hidden;
}

.about-news {
  margin: 55px auto;
  width: 1240px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.about-news .label {
  color: var(--text-color);
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  width: 465px;
}

.about-news .news-container {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  gap: 20px;
}

.about-news .news-container .news-card {
  width: 32%;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid var(--about-news-card-border);
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 400px;
}

.about-news .news-container .news-card img {
  width: 100%;
  border-radius: 15px;
}

.about-news .news-container .news-card .news-type {
  display: inline-flex;
  padding: 9px 31px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  background: rgba(45, 142, 255, 0.10);
  width: min-content;
  color: #2D8EFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.about-news .news-container .news-card .label,
.about-news .news-container .news-card .description {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  width: auto;
}

.about-news .news-container .news-card .label {
  font-weight: 600;
}

.about-news .news-container .news-card .description {
  font-weight: 400;
}

.about-news .news-container .news-card a {
  color: #2D8EFF;
  text-decoration: none;
}


@media (max-width: 1280px) {

  .about-news {
    width: calc(100% - 40px);
  }
}


@media (max-width: 800px) {
  .about-news .label {
    font-size: 35px;
    line-height: 40px;
  }

  .about-news .news-container .news-card {
    min-width: fit-content;
    width: 100%;
  }
}

:root {
  --about-news-background: #F9FAFB;
  --about-news-card-border: #E7EAEE;
}

[data-theme='dark'] {
  --about-news-background: #1A1F32;
  --about-news-card-border: #F1F1F133;
}