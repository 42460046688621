.hello-block-background {
  width: 100%;
  background-color: #2D8EFF1A;
  /* background-image: var(--become-partners-hello-background); */
  background-repeat: no-repeat;
  /* background-size: 115% 200%; */
  background-position: -80px 30px;
}

.hello-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1240px;
  margin: 86px auto;
}

.hello-block .title {
  width: 765px;
  font-size: 80px;
  font-weight: 500;
  line-height: 80px;
  color: var(--text-color);
  text-align: center;
  margin-top: 15px;
}

.hello-block .description {
  width: 528px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--text-color);
  opacity: 0.8;
  margin-top: 15px;
  text-align: center;
}

.hello-block .buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.hello-block .buttons .primary-button,
.hello-block .buttons .secondary-button {
  border-radius: 15px;
  padding: 15px 55px;
  border: none;
  text-wrap: nowrap;
}

.hello-block .buttons .primary-button {
  background-color: #2D8EFF;
  color: white;
}

.hello-block .buttons .secondary-button {
  background-color: transparent;
  color: #2D8EFF;
}

.hello-block .features {
  display: flex;
  gap: 10px;
  margin-top: 35px;
}

.hello-block .features .feature {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  width: 257px;
  height: 70px;
  border-radius: 15px;
  background-color: var(--hello-block-features);
}

.takeprofit {
  position: absolute;
  top: 115px;
}

.growthReferals {
  position: absolute;
  top: 418px;
}

.sumProfit {
  position: absolute;
  top: 156px;
  right: 0;
}

.profitBalance {
  position: absolute;
  top: 350px;
  right: 0;
}

@media (max-width: 1350px) {
  .hello-block .title {
    font-size: 45px;
    width: 431px;
    height: 114px;
    line-height: normal;
  }

  .takeprofit {
    height: 160px;
    top: 150px;
  }

  .growthReferals {
    height: 119px;
    top: 340px;
  }

  .sumProfit {
    height: 100px;
    top: 160px;
  }

  .profitBalance {
    height: 160px;
    top: 300px;
  }
}

@media (max-width: 1280px) {
  .hello-block {
    width: 90%;
  }
}

@media (max-width: 850px) {
  .hello-block-background {
    background-repeat: repeat;
  }
  
  .hello-block .title {
    font-size: 35px;
    width: auto;
    height: auto;
    /* line-height: normal; */
  }

  .takeprofit,
  .growthReferals,
  .sumProfit,
  .profitBalance {
    display: none;
  }

  .hello-block .features {
    flex-direction: column;
    width: 100%;
  }

  .hello-block .features .feature {
    width: 100%;
  }

  .hello-block .buttons {
    width: 100%;
    justify-content: space-between;
  }

  .hello-block .description {
    width: 100%;
  }

  .hello-block .buttons .primary-button,
  .hello-block .buttons .secondary-button {
    flex: 1 1;
    padding: 15px 10px;
  }
}

:root {
  --become-partners-hello-background: url('@assets/partners/becomePartners/helloBlock/backgroundPoints-light.svg');
  --hello-block-features: white;
}

[data-theme='dark'] {
  --become-partners-hello-background: url('@assets/partners/becomePartners/helloBlock/backgroundPoints-dark.svg');
  --hello-block-features: #1A1F32;
}