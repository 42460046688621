.edit-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.edit-popup {
  background-color: var(--wallet-balance-background);
  border-radius: 15px;
  padding: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.edit-popup h2 {
  margin: 0;
  font-size: 24px;
  text-align: center;
  color: var(--text-color);
}

.edit-popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.edit-popup-buttons button {
  padding: 10px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  flex: 1;
  font-size: 16px;
}

.edit-popup-buttons button:first-child {
  background-color: transparent;
  border: 1px solid #2D8EFF;
  color: #2D8EFF;
}

.edit-popup-buttons button:last-child {
  background-color: #2D8EFF;
  color: white;
}

@media (max-width: 600px) {
  .edit-popup {
    width: 90%;
  }
}