.partnership-advantages {
  display: flex;
  flex-direction: column;
  width: 1240px;
  margin: 86px auto 0 auto;
}

.partnership-advantages .label {
  color: var(--text-color);
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
}

.partnership-advantages-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 25px;
}

.partnership-advantage-card {
  width: 32%;
  border-radius: 15px;
  border: 1px solid var(--partnership-advantage-border);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.partnership-advantage-card .image-block {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  background: rgba(45, 142, 255, 0.10);
  display: flex;
  align-items: center;
  justify-content: center;
}

.partnership-advantage-card .card-label {
  color: var(--text-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.partnership-advantage-card .card-description {
  color: var(--text-color);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.partnership-advantage-card .more-info-btn {
  color: var(--text-color);
  width: auto;
  padding: 13px 55px;
  gap: 5px;
  border-radius: 15px;
  border: 1px solid var(--partnership-advantage-btn-border);
  background-color: transparent;
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}



@media (max-width: 1280px) {
  .partnership-advantages {
    width: calc(100% - 40px);
  }

  .partnership-advantage-card {
    width: 49%;
  }
}

@media (max-width: 810px) {
  .partnership-advantage-card {
    width: 100%;
  }

  .partnership-advantages .label {
    font-size: 45px;
  }
}

:root {
  --partnership-advantage-border: #E7EAEE;
  --partnership-advantage-btn-border: #E7EAEE;
}

[data-theme='dark'] {
  --partnership-advantage-border: #E5E5E51A;
  --partnership-advantage-btn-border: rgba(229, 229, 229, 0.20);
}