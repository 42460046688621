.notification-dropdown-menu {
  position: absolute;
  top: 70px;
  right: 0;
  background-color: var(--header-dropdown-menu);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 255px;
  z-index: 1000;
  color: var(--text-color);
  text-align: left;
  max-height: 300px;
  display: flex;
  flex-direction: column;
}

.notification-title {
  margin: 10px 15px 10px 15px;
  font-weight: 600;
}

.notification-type-container {
  display: flex;
  width: 100%;
}

.notification-type {
  flex: 1 1;
  text-align: center;
  color: var(--text-color);
  cursor: pointer;
  border-bottom: 1px solid #d9d9d949;
}

.notification-type.active {
  color: #2D8EFF;
  border-bottom: 1px solid #2D8EFF;
}

.notification-list-container {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.notification-list-container::-webkit-scrollbar {
  width: 4px; /* Ширина скроллбара */
}

.notification-list-container::-webkit-scrollbar-track {
  background-color: #2D8EFF1A; /* Цвет фона трека */
  border-radius: 10px; /* Скругление трека */
}

.notification-list-container::-webkit-scrollbar-thumb {
  background-color: #2d8fffaf; /* Цвет бегунка */
  border-radius: 10px; /* Скругление бегунка */
}

.notification-list-container::-webkit-scrollbar-thumb:hover {
  background-color: #2d8fff; /* Цвет бегунка при наведении */
}

.notification-container {
  display: flex;
  padding: 10px 15px;
  border-bottom: 1px solid #d9d9d949;
}

.notification-container:hover {
  background-color: #00000005;
}

.notification-icon {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid #E7EAEE;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: 2px;
}

.notification-text {
  flex: 1;
}

.notification-label {
  color: var(--text-color);
}

.notification-info {
  color: var(--text-color);
  opacity: 0.3;
  font-size: 12px;
}