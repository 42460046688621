.partner-statistic {
  flex: 1 1;
  border-radius: 16px;
  padding: 20px;
  border: 1px solid var(--partnership-borders);
  font-family: 'Inter', sans-serif;
  color: #222222;
  height: 530px;
  width: 50%;
}

.partner-statistic .label {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #1a1a1a;
}

.range {
  font-size: 14px;
  font-weight: 400;
  color: #8b94a4;
}

.referals-statistic {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
  border: 1px solid var(--partnership-borders);
  border-radius: 15px;
  height: 56px;
  padding: 0 15px;
  color: var(--text-color);
}

.circle-30x30 {
  width: 30px;
  height: 30px;
  background-color: #2D8EFF;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-15x15 {
  width: 15px;
  height: 15px;
  fill: currentColor;
}

.count-referals {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: #1a1a1a;
}

.difference-referals {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #4ac76d;
}

.background-img {
  width: 45px;
  height: 45px;
  fill: #0000001A;
}

.chart-container {
  margin-top: 20px;
  height: 300px;
  position: relative;
}

.canvas-tooltip {
  position: absolute;
  background-color: white;
  border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  padding: 8px;
  font-size: 12px;
  color: #222222;
  z-index: 10;
  pointer-events: none;
}

.canvas-tooltip span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.canvas-tooltip span img {
  width: 12px;
  height: 12px;
}


@media (max-width: 1150px) {
  .partner-statistic {
    width: 100%;
  }
}