.telegram-post {
  background-color: var(--telegram-post-background);
  border: none;
  width: max-content;
  max-width: 270px;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: absolute;
  bottom: 30px;
  left: 15px;
  text-align: left;
  z-index: 975;
  color: var(--telegram-post-text-color);
  border-radius: 8px 8px 8px 0;
}

.telegram-post.loading {
  line-height: 18px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--telegram-post-text-color);
}

.telegram-post .post-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.telegram-post .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #ccc;
}

.telegram-post .post-author {
  font-weight: bold;
  font-size: 14px;
  color: var(--telegram-post-author-color);
}

.telegram-post .post-date {
  font-size: 12px;
  color: var(--telegram-post-date-color);
}

.telegram-post .post-content {
  font-size: 15px;
  line-height: 1.6;
  color: var(--telegram-post-text-color);
  max-width: 500px;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  max-height: 300px;
  /* Скрыть текст, выходящий за пределы блока */
  text-overflow: ellipsis;
  /* Добавить троеточие в конце */
  display: -webkit-box;
  -webkit-line-clamp: 5;
  /* Ограничить количество строк */
  -webkit-box-orient: vertical;
}

.telegram-post .post-media {
  margin-top: 10px;
  border-radius: 8px;
  overflow: hidden;
  max-height: 250px;
  display: block;
}

.telegram-post .post-media img {
  width: 100%;
  height: auto;
  display: block;
}

.telegram-post .post-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
  color: var(--telegram-post-footer-color);
}

.telegram-post .post-footer .post-view-count {
  display: flex;
  align-items: center;
}

.telegram-post .post-footer .post-view-count svg {
  margin-right: 4px;
  fill: var(--telegram-post-icon-color);
}

.telegram-post .post-timestamp {
  color: var(--telegram-post-footer-color);
}

.telegram-post .post-reactions {
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
}

.telegram-post .reaction {
  display: flex;
  align-items: center;
  margin-right: 10px;
  background-color: var(--reaction-background);
  border-radius: 12px;
  padding: 4px 8px;
  font-size: 12px;
}

.telegram-post .reaction-icon {
  margin-right: 4px;
  /* Здесь можно добавить стили для разных типов реакций */
}

.telegram-post .reaction-count {
  color: var(--telegram-post-text-color);
}

.telegram-post .add-reaction button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-right: 5px;
}

.telegram-post .add-reaction button:hover {
  transform: scale(1.2);
}

/* Темная тема */
:root {
  --telegram-post-background: white;
  --telegram-post-text-color: #333;
  --telegram-post-author-color: #333;
  --telegram-post-date-color: #888;
  --telegram-post-footer-color: #aaa;
  --telegram-post-icon-color: #aaa;
  --reaction-background: #f0f0f0;
}

[data-theme='dark'] {
  --telegram-post-background: #484C5B;
  --telegram-post-text-color: #e0e0e0;
  --telegram-post-author-color: #ffffff;
  --telegram-post-date-color: #cccccc;
  --telegram-post-footer-color: #bbbbbb;
  --telegram-post-icon-color: #bbbbbb;
  --reaction-background: #5a5f75;
}