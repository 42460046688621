.about-hello-block-background {
  background-image: var(--about-hello-background);
}

.about-hello-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1240px;
  margin: 0 auto;
  padding-top: 85px;
  gap: 15px;
}

.about-hello-block .label {
  color: var(--text-color);
  text-align: center;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
}

.about-hello-block .description {
  width: 541px;
  color: var(--text-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.about-hello-block .buttons {
  display: flex;
  gap: 10px;
}

.about-hello-block .buttons .primary-button {
  display: inline-flex;
  padding: 15px 55px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  background: #2D8EFF;
  border: none;
  color: white;
  text-wrap: nowrap;
}

.about-hello-block .buttons .secondary-button {
  display: inline-flex;
  padding: 15px 55px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  text-wrap: nowrap;
}

.about-hello-block svg {
  width: 100%;
  height: 400px;
  flex-shrink: 0;
}

@media (max-width: 1280px) {
  .about-hello-block {
    width: calc(100% - 40px);
  }
}

@media (max-width: 1000px) {
  .about-hello-block .label {
    font-size: 45px;
    width: 500px;
    line-height: normal;
  }
}

@media (max-width: 800px) {
  .about-hello-block .label {
    font-size: 35px;
    width: 100%;
    line-height: 40px;
  }

  .about-hello-block .description {
    width: 100%;
  }

  .about-hello-block svg {
    width: 100%;
    height: 260px;
    flex-shrink: 0;
  }

  .about-hello-block .buttons {
width: 100%;
  }

  .about-hello-block .buttons .primary-button,
  .about-hello-block .buttons .secondary-button {
    padding: 15px 0;
    width: 50%;
  }
}

:root {
  --about-hello-background: url('@assets/about/helloBlock/background-light.svg');

}

[data-theme='dark'] {
  --about-hello-background: url('@assets/about/helloBlock/background-light.svg');

}