/* AuthWindow.css */

.auth-window {
  background: var(--auth-window-background);
  padding: 10px 8px 10px 20px;
  border-radius: 20px;
  width: 850px;
  /*max-height: 600px;*/
  height: min-content;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: auto;
  margin-top: 20px; /* Отступ от верхней границы экрана */
}

.auth-content {
  display: flex;
  width: 100%;
}

.auth-form {
  flex: 1;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.auth-form .label {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  /*margin-bottom: 10px;*/
}

.auth-form .label img {
  display: none;
  margin: 12px 12px 0 0;
}

.auth-form h2 {
  margin-bottom: 10px;
  width: 320px;
  text-align: left;
  font-size: 28px;
  font-weight: 600;
  font-family: 'EuclidCircularA-Bold', sans-serif;
}

.auth-form input {
  width: 100%;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
}

.auth-form button {
  width: 100%;
  padding: 10px;
  background-color: #2D8EFF;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.switch-auth-window {
  text-align: left;
  margin: 1rem 0 0 0;
  color: var(--text-color);
  display: flex;
  gap: 5px;
}

.switch-auth-window div {
  color: #2D8EFF;
  cursor: pointer;
}

.auth-form form {
  text-align: center;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.text-blue-left {
  color: #2D8EFF;
  text-align: left !important;
  margin: 0 !important;
}

.blue-rectangle {
  background-color: var(--auth-window-info-block);
  color: white;
  border-radius: 20px;
  padding: 20px;
  width: 382px;
  height: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.overlay-text {
  font-size: 42px;
  color: #ffffff;
  line-height: 1.2;
  z-index: 1000;
  text-align: left;
  margin-bottom: 0;
}

/* Медиазапрос для мобильных устройств */
@media (max-width: 768px) {
  .blue-rectangle {
    display: none;
  }

  .auth-window {
    width: 90%;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .auth-form {
    padding-right: 0;
  }

  .auth-content {
    flex-direction: column;
    align-items: stretch;
  }

  .auth-form h2 {
    margin-bottom: 10px;
    width: 290px;
    text-align: left;
    font-size: 25px;
  }

  .auth-form .label img {
    display: block;
  }

  .auth-form button {
    margin-top: 135px !important;
  }
}

@media (max-height: 600px) {
  .blue-rectangle {
    display: none;
  }

  .auth-window-overlay {
    align-items: normal;
    overflow-y: auto;
  }

  .auth-window {
    margin: 20px 0;
  }
}

@media (max-height: 650px) {
  .auth-form .label img {
    display: block;
  }

  .auth-form button {
    margin-top: 20px !important;
  }
}

:root {
  --auth-window-info-block: #2D8EFF;
  --auth-window-background: white;
  --auth-info-item: white; 
}

[data-theme='dark'] {
  --auth-window-info-block: #2A78D6;
  --auth-window-background: #1A1F32;
  --auth-info-item: #3f86da; 
}